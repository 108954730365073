import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

import axios from "axios";

import { HeaderUser } from "../HeaderUser/HeaderUser";
import { ProductList } from "./ProductList";
import { CrmList } from "./CrmList";
import { Product } from "./Product";
import { Modal } from "../Modal/Modal";
import { ModalProvider } from "../Modal/ModalContext";
import { SearchProvider } from "../Search/SearchContext";
import { Education } from "./Education/Education";
import { NotFound } from "../404/404";

export const Projects = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token_user");

    if (!token) {
      navigate("/login");
      return;
    }

    axios.defaults.headers.common["Authorization-User"] = `Bearer ${token}`;

    const checkToken = async () => {
      try {
        await axios.get("/api/protected-user", {
          headers: { "Authorization-User": `Bearer ${token}` },
        });
      } catch {
        localStorage.removeItem("token_user");
        navigate("/login");
      }
    };

    checkToken();
  }, [navigate]);

  return (
    <SearchProvider>
      <ModalProvider>
        <div>
          <HeaderUser />
          <Routes>
            <Route path="/" element={<CrmList />} />
            <Route path="/crm/:id" element={<ProductList />} />
            <Route path="/product/:id" element={<Product />} />
            <Route path="/education" element={<Education />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Modal />
        </div>
      </ModalProvider>
    </SearchProvider>
  );
};
