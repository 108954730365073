import React from "react";
import axios from "axios";
import Accordion from "../../Accordion/Accordion";

export const RightPanel = () => {
  const [rightPanels, setRightPanels] = React.useState([]);

  React.useEffect(() => {
    axios
      .get(`/api/table/right-panel`)
      .then((response) => {
        setRightPanels(response.data);
      })
      .catch((error) => console.error("Помилка:", error));
  }, []);
  return (
    <>
      <div>
        <ul>
          <li>
            <Accordion panels={rightPanels} />
          </li>
        </ul>
      </div>
    </>
  );
};
