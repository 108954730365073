import React, { useState } from "react";

import { Info } from "./Info/Info";
import { EditRightPanelScripts } from "./EditRightPanelScripts/EditRightPanelScripts";
import { EditBookManager } from "./EditBookManager/EditBookManager";
import { EditBlockLearning } from "./EditBlockLearning/EditBlockLearning";
import { EditAdminUser } from "./EditAdminUser/EditAdminUser";

export const AdminSettings = () => {
  const [selectedComponent, setSelectedComponent] = useState("adminUser");

  const renderContent = () => {
    switch (selectedComponent) {
      case "rightPanel":
        return <EditRightPanelScripts />;
      case "bookManager":
        return <EditBookManager />;
      case "learningBlock":
        return <EditBlockLearning />;
      case "adminUser":
        return <EditAdminUser />;
      default:
        return <EditAdminUser />;
    }
  };

  return (
    <div className="admin_main">
      <div className="container--big--admin">
        <div className="admin_settings_container">
          <div className="admin_settings_panel">
            <div className="admin_settings_panel_nav">
              <button
                style={{
                  color:
                    selectedComponent === "adminUser"
                      ? "var(--main)"
                      : "var(--text-color)",
                }}
                className="admin_settings_panel_nav_button"
                onClick={() => setSelectedComponent("adminUser")}
              >
                Налаштування адміністратора
              </button>
              <button
                className="admin_settings_panel_nav_button"
                style={{
                  color:
                    selectedComponent === "rightPanel"
                      ? "var(--main)"
                      : "var(--text-color)",
                }}
                onClick={() => setSelectedComponent("rightPanel")}
              >
                Редагувати праву панель скриптів
              </button>
              <button
                style={{
                  color:
                    selectedComponent === "bookManager"
                      ? "var(--main)"
                      : "var(--text-color)",
                }}
                className="admin_settings_panel_nav_button"
                onClick={() => setSelectedComponent("bookManager")}
              >
                Книга менеджера
              </button>
              <button
                style={{
                  color:
                    selectedComponent === "learningBlock"
                      ? "var(--main)"
                      : "var(--text-color)",
                }}
                className="admin_settings_panel_nav_button"
                onClick={() => setSelectedComponent("learningBlock")}
              >
                Блок "Навчання"
              </button>
            </div>
          </div>
          <div className="admin_settings_content">{renderContent()}</div>
        </div>
      </div>
    </div>
  );
};
