import React, { useState, useEffect } from "react";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { useNotification } from "../../NotificationModal/NotificationModal";
import axios from "axios";

export const EditRightPanelScripts = () => {
  const [scripts, setScripts] = useState([]);
  const { showNotification } = useNotification();

  const fetchScripts = async () => {
    const response = await axios.get("/api/table/right_panel");
    setScripts(response.data);
  };

  useEffect(() => {
    fetchScripts();
  }, []);

  const handleInputChange = (id, field, value) => {
    setScripts((prevScripts) =>
      prevScripts.map((script) =>
        script.id === id ? { ...script, [field]: value } : script
      )
    );
  };

  const handleAddScript = async () => {
    try {
      const response = await axios.post("/api/add_right_panel", {
        label: "Новий скрипт",
        content: "Новий скрипт",
      });
      console.log(response.data);
      if (response.data && response.data.id) {
        setScripts((prev) => [
          ...prev,
          {
            id: response.data.id,
            label: "Новий скрипт",
            content: "Новий скрипт",
          },
        ]);
        fetchScripts();
        showNotification("Скрипт успішно додано!", "success");
        document
          .querySelector(".edit_admin_block")
          .lastElementChild.scrollIntoView({ behavior: "smooth" });
      }
    } catch (error) {
      console.error("Помилка при додаванні скрипта:", error);
      showNotification("Помилка при додаванні скрипта");
    }
  };

  const handleUpdateScript = async (e, id) => {
    e.preventDefault();
    const updatedScript = scripts.find((script) => script.id === id);

    try {
      const response = await fetch(`/api/right_panel/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedScript),
      });

      if (response.ok) {
        showNotification("Скрипт успішно обновлен!");
      } else {
        console.error("Помилка обновлення скрипта");
      }
    } catch (error) {
      console.error("Помилка сети:", error);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Видалити скрипт?")) {
      try {
        await axios.delete(`/api/delete_right_panel/${id}`);
        setScripts(scripts.filter((script) => script.id !== id));
        showNotification("Скрипт успішно видалено!", "success");
      } catch (error) {
        console.error("Помилка видалення скрипта:", error);
        showNotification("Помилка видалення скрипта", "error");
      }
    }
  };

  return (
    <div className="edit_admin_block">
      <div className="admin_main_title" style={{ width: "100%" }}>
        <h1>Редагувати праву панель скриптів</h1>
        <button className="admin_main_title_button" onClick={handleAddScript}>
          Додати скрипт
        </button>
      </div>
      {scripts.map((script) => (
        <div key={script.id} style={{ width: "100%" }}>
          <form
            className="admin_wrap"
            onSubmit={(e) => handleUpdateScript(e, script.id)}
          >
            <div className="admin_wrap_label">
              <input
                type="text"
                name="label"
                value={script.label}
                onChange={(e) =>
                  handleInputChange(script.id, "label", e.target.value)
                }
              />
            </div>
            <div className="admin_wrap_content">
              <ReactQuill
                className="ql-editor-fixed"
                value={script.content}
                onChange={(content) =>
                  handleInputChange(script.id, "content", content)
                }
                modules={{
                  toolbar: [
                    [{ header: [1, 2, 3, false] }],
                    ["bold", "italic", "underline", "strike"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["link"],
                    [{ color: [] }, { background: [] }],
                    [{ align: [] }],
                    ["clean"],
                  ],
                }}
                formats={[
                  "header",
                  "bold",
                  "italic",
                  "underline",
                  "strike",
                  "list",
                  "bullet",
                  "link",
                  "align",
                  "color",
                  "background",
                ]}
              />
            </div>
            <div className="admin_wrap_buttons">
              <input className="edit" type="submit" value="Зберегти " />
              <div
                className="delete delete-div"
                onClick={() => handleDelete(script.id)}
              >
                Видалити
              </div>
            </div>
          </form>
        </div>
      ))}
    </div>
  );
};
