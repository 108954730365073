import React, { useState } from "react";
import { useModal } from "../../Modal/ModalContext";
import axios from "axios";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { useNotification } from "../../NotificationModal/NotificationModal";

export const AddBlockLearning = () => {
  const { closeModal, openModal } = useModal();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [videoUrl, setVideoUrl] = useState("");

  const { showNotification } = useNotification(); // Хук для уведомлений

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!title) {
      alert("Заполните название!");
      return;
    }
    try {
      await axios.post("/api/add_educations", {
        title,
        content,
        url_video: videoUrl,
      });
      closeModal();
      showNotification("Матеріал успішно додано!", "success");
    } catch (error) {
      console.error("Помилка добавлення матеріалу:", error);
      showNotification("Не вдалося додати матеріал!", "failed");
    }
  };

  const handleChangeContent = (content) => {
    setContent(content);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <h2>Додати навчальний матеріал</h2>
        <div className="admin_main_content_form_input">
          <label>
            Назва:<span className="star__red">*</span>
          </label>
          <input
            type="text"
            placeholder="Назва навчального матеріалу"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div className="admin_main_content_form_input">
          <label>Опис:</label>
          <ReactQuill
            className="ql-editor-fixed"
            value={content}
            onChange={(content) => handleChangeContent(content)}
            modules={{
              toolbar: [
                [{ header: [1, 2, 3, false] }],
                ["bold", "italic", "underline", "strike"],
                [{ list: "ordered" }, { list: "bullet" }],
                ["link"],
                [{ color: [] }, { background: [] }],
                [{ align: [] }],
                ["clean"],
              ],
            }}
            formats={[
              "header",
              "bold",
              "italic",
              "underline",
              "strike",
              "list",
              "bullet",
              "link",
              "align",
              "color",
              "background",
            ]}
          />
        </div>
        <div className="admin_main_content_form_input">
          <label>URL відео:</label>
          <input
            type="text"
            placeholder="URL відео"
            onChange={(e) => setVideoUrl(e.target.value)}
          />
        </div>
        <button className="action_button" type="submit">
          Додати
        </button>
      </form>
    </>
  );
};
