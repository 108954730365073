import React from "react";
import axios from "axios";
import { useModal } from "../../Modal/ModalContext";
import { useNotification } from "../../NotificationModal/NotificationModal";

export const EditUserForm = ({ user, refreshData }) => {
  const [username, setUsername] = React.useState(user.username);
  const [comment, setComment] = React.useState(user.comment);
  const [crmList, setCrmList] = React.useState([]); // Список доступных CRM
  const [selectedCrm, setSelectedCrm] = React.useState([]); // Выбранные CRM
  const [usernameError, setUsernameError] = React.useState("");

  const [created_at, setCreated_at] = React.useState("");
  const [updated_at, setUpdated_at] = React.useState("");

  const { closeModal } = useModal();
  const { showNotification } = useNotification();

  // Загрузка доступных CRM и выбор связанных CRM
  React.useEffect(() => {
    axios
      .get("/api/table/crm")
      .then((response) => {
        setCrmList(response.data);
        // Преобразуем связанные CRM в массив чисел
        setSelectedCrm(
          user.crm
            ? user.crm
                .split(", ")
                .map((crmName) => {
                  const crm = response.data.find(
                    (item) => item.name === crmName
                  );
                  return crm ? crm.id : null;
                })
                .filter(Boolean)
            : []
        );
        setCreated_at(user.created_at);
        setUpdated_at(user.updated_at);
      })
      .catch((error) => console.error("Помилка загрузки CRM:", error));
  }, [user.crm]);

  const handleUsernameChange = (e) => {
    const value = e.target.value;
    const isValid = /^[a-zA-Z0-9]*$/.test(value);
    setUsername(value);

    if (!isValid) {
      setUsernameError(
        "Допустимі тільки латинські літери та цифри без пробілів"
      );
    } else {
      setUsernameError("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .put(`/api/edit_user/${user.id}`, {
        username,
        comment,
        created_at,
        updated_at,
        crmIds: selectedCrm, // Здесь должны быть только числа
      })
      .then(() => {
        closeModal();
        refreshData();
        showNotification("Користувача успішно оновлено!", "success");
      })
      .catch((error) => {
        console.error("Помилка обновлення:", error);
        showNotification("Не вдалося оновити користувача!", "failed");
      });
  };

  const handleCrmChange = (crmId, isChecked) => {
    setSelectedCrm((prev) =>
      isChecked
        ? [...prev, Number(crmId)]
        : prev.filter((id) => id !== Number(crmId))
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Редагувати користувача</h2>
      <div className="admin_main_content_form_input">
        <label>
          Ім'я:<span className="star__red">*</span>
        </label>
        <input
          type="text"
          value={username}
          onChange={handleUsernameChange}
          required
        />
        {usernameError && (
          <p className="generate_password_text">{usernameError}</p>
        )}
      </div>
      <div className="admin_main_content_form_input">
        <label>Коментар:</label>
        <textarea
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </div>
      <div className="admin_main_content_form_input">
        <label>
          CRM (Оберіть які необхідні):<span className="star__red">*</span>
        </label>
        <div className="admin_main_content_form_checkbox_container">
          {crmList.map((crm) => (
            <div className="admin_main_content_form_checkbox" key={crm.id}>
              <input
                type="checkbox"
                id={crm.id}
                value={crm.id}
                onChange={(e) =>
                  handleCrmChange(crm.id.toString(), e.target.checked)
                }
                checked={selectedCrm.includes(crm.id)}
              />
              <label htmlFor={`crm-${crm.id}`}>{crm.name}</label>
            </div>
          ))}
        </div>
      </div>
      <button className="action_button" type="submit">
        Зберегти
      </button>
    </form>
  );
};
