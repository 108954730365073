import React, { useState, useEffect, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";

import { useNotification } from "../../NotificationModal/NotificationModal";

export const ProductFields = ({ productId }) => {
  const [fields, setFields] = useState([]);
  const { showNotification } = useNotification();
  const quillRefs = useRef({});
  useEffect(() => {
    fetchFields();
  }, [productId]);

  const fetchFields = async () => {
    try {
      const response = await axios.get(
        `/api/product_fields/${productId}/fields`
      );
      setFields(response.data);
    } catch (error) {
      console.error("Помилка загрузки полей:", error);
    }
  };

  const handleAddField = async () => {
    try {
      const response = await axios.post(
        `/api/add_product_fields/${productId}/fields`,
        {
          label: "Новe поле",
          content: "",
        }
      );
      setFields((prev) => [
        ...prev,
        { id: response.data.id, label: "Нове поле", content: "" },
      ]);
    } catch (error) {
      console.error("Помилка добавления поля", error);
      showNotification("Помилка добавления поля", "error");
    }
  };

  const handleUpdateField = async (id, updatedField) => {
    try {
      await axios.put(`/api/product_fields/fields/${id}`, updatedField);
      showNotification("Скрипт успішно оновлено", "success");
    } catch (error) {
      console.error("Помилка оновлення скрипта:", error);
      showNotification("Помилка оновлення скрипта", "error");
    }
  };

  const handleDeleteField = async (id) => {
    if (window.confirm("Ви впевнені, що хочете видалити це поле?")) {
      try {
        await axios.delete(`/api/delete_product_fields/fields/${id}`);
        setFields((prev) => prev.filter((field) => field.id !== id));
      } catch (error) {
        console.error("Помилка видалення поля:", error);
      }
    }
  };

  const handleInputChange = async (id, field, value) => {
    const imgTagRegex = /<img[^>]+src="data:image\/[^;]+;base64,([^"]+)"/g;
    let match;
    let newValue = value;

    while ((match = imgTagRegex.exec(value)) !== null) {
      const base64Data = match[1];

      const file = base64ToFile(base64Data, "image.png");

      try {
        const imageUrl = await uploadImage(file);
        newValue = newValue.replace(match[0], `<img src="${imageUrl}" />`);
      } catch (error) {
        console.error("Помилка загрузки зображення:", error);
      }
    }

    setFields((prevFields) =>
      prevFields.map((item) =>
        item.id === id ? { ...item, [field]: newValue } : item
      )
    );
  };

  const base64ToFile = (base64String, filename) => {
    const byteString = atob(base64String);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([arrayBuffer], { type: "image/png" });
    return new File([blob], filename, { type: "image/png" });
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await axios.post("/api/upload_image_fields", formData);
      return response.data.imageUrl;
    } catch (error) {
      console.error("Помилка загрузки зображення", error);
      throw error;
    }
  };

  return (
    <div>
      <div className="admin_main_content_form_panel_scripts_title">
        <h3>Редагувати панель скриптів</h3>
        <button className="edit_reglament" onClick={handleAddField}>
          Додати скрипт
        </button>
      </div>
      <div className="admin_mian_product_fields">
        {fields.map((field) => (
          <div
            className="admin_main_content_form_input"
            key={field.id}
            style={{ marginBottom: "20px" }}
          >
            <input
              type="text"
              value={field.label}
              onChange={(e) =>
                handleInputChange(field.id, "label", e.target.value)
              }
              placeholder="Назва скрипта"
            />
            <ReactQuill
              className="ql-editor-fixed"
              value={field.content}
              onChange={(content) =>
                handleInputChange(field.id, "content", content)
              }
              modules={{
                toolbar: [
                  [{ header: [1, 2, 3, false] }],
                  ["bold", "italic", "underline", "strike"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["link", "image"],
                  [{ color: [] }, { background: [] }],
                  [{ align: [] }],
                  ["clean"],
                ],
              }}
              formats={[
                "header",
                "bold",
                "italic",
                "underline",
                "strike",
                "list",
                "bullet",
                "link",
                "image",
                "align",
                "color",
                "background",
              ]}
            />
            <div className="div_save_delete_wrap">
              <button
                style={{ width: "fit-content" }}
                className="edit"
                onClick={() => handleUpdateField(field.id, field)}
              >
                Зберегти
              </button>
              <button
                style={{ width: "fit-content" }}
                className="delete"
                onClick={() => handleDeleteField(field.id)}
              >
                Видалити
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
