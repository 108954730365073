import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/img/logo.png";
import styles from "./Login.module.scss";

export const Login = () => {
  React.useEffect(() => {
    window.history.replaceState(null, "", "/login");
  }, []);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post("/api/login_user", {
        username,
        password,
      });

      localStorage.setItem("token_user", response.data.token);
      axios.defaults.headers.common[
        "Authorization-User"
      ] = `Bearer ${response.data.token}`;
      navigate("/projects");
    } catch (err) {
      setError(err.response?.data?.message || "Помилка авторизації");
    }
  };

  return (
    <div className={styles.login}>
      <div className={styles.login__inner}>
        <form className={styles.LoginForm} onSubmit={handleSubmit}>
          <img src={logo} alt="logo" className={styles.login__logo} />
          {error && <div className={styles.error}>{error}</div>}

          <div className={styles.LoginForm_field}>
            <input
              className={styles.Input_input}
              type="text"
              placeholder="Ім'я користувача"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              autoComplete="username" // Добавлено
            />
          </div>
          <div className={styles.LoginForm_field_password}>
            <input
              className={styles.Input_input}
              type="password"
              placeholder="Пароль"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="current-password" // Добавлено
            />
          </div>
          <button className={styles.Button_button} type="submit">
            Увійти
          </button>
        </form>
      </div>
    </div>
  );
};
