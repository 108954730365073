import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/img/logo.png";
import styles from "./LoginAdmin.module.scss";

export const LoginAdmin = () => {
  React.useEffect(() => {
    window.history.replaceState(null, "", "/login-admin-88cjw2d0b1mx9iis2");
  }, []);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post("/api/login_admin", {
        username,
        password,
      });

      localStorage.setItem("token_admin", response.data.token);
      axios.defaults.headers.common[
        "Authorization-Admin"
      ] = `Bearer ${response.data.token}`;
      navigate("/dashboard");
    } catch (err) {
      setError(err.response?.data?.message || "Помилка авторизації");
    }
  };

  return (
    <div className={styles.login}>
      <div className={styles.login__inner}>
        <form className={styles.LoginForm} onSubmit={handleSubmit}>
          <img src={logo} alt="logo" className={styles.login__logo} />
          {error && <div className={styles.error}>{error}</div>}

          <div className={styles.LoginForm_field_h3}>
            <h3>Вхід в адмінпанель</h3>
          </div>
          <div className={styles.LoginForm_field}>
            <input
              className={styles.Input_input}
              type="text"
              placeholder="Ім'я адміністратора"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className={styles.LoginForm_field_password}>
            <input
              className={styles.Input_input}
              type="password"
              placeholder="Пароль адміністратора"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button className={styles.Button_button} type="submit">
            Увійти
          </button>
        </form>
      </div>
    </div>
  );
};
