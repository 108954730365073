import React, { useState, useEffect } from "react";
import styles from "./BookManager.module.scss";
import modalStyles from "../../Modal/Modal.module.scss";

import axios from "axios";

export const BookManager = () => {
  const [bookManager, setBookManager] = useState([]);

  useEffect(() => {
    axios.get("/api/book_manager").then((response) => {
      setBookManager(response.data);
    });
  }, []);

  return (
    <div>
      <h1 className={styles.BookManager_main_title}>Книга менеджера</h1>

      <div
        className={[
          styles.BookManager_main_content,
          modalStyles.modal__body,
        ].join(" ")}
      >
        {bookManager.map((item) => (
          <div key={item.id}>
            <div className={styles.BookManager_wrap}>
              <div className={styles.BookManager_wrap_label}>{item.label}</div>
              <div
                className={styles.BookManager_wrap_content}
                dangerouslySetInnerHTML={{ __html: item.content }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
