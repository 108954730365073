import React, { useState, useEffect } from "react";

export const Sort = ({
  fetchSortedData,
  defaultSort = "id",
  defaultOrder = "asc",
}) => {
  const [sort, setSort] = useState(defaultSort);
  const [order, setOrder] = useState(defaultOrder);

  useEffect(() => {
    setSort(defaultSort);
    setOrder(defaultOrder);
  }, [defaultSort, defaultOrder]);

  const handleSortChange = (e) => {
    const newSort = e.target.value;
    setSort(newSort);
    fetchSortedData(newSort, order);
  };

  const handleOrderChange = (e) => {
    const newOrder = e.target.value;
    setOrder(newOrder);
    fetchSortedData(sort, newOrder);
  };

  return (
    <div className="sort_container">
      <div className="sort_item">
        <p>Сортування по:</p>
        <select value={sort} onChange={handleSortChange}>
          <option value="id">По ID</option>
          <option value="name">По назві</option>
          <option value="created_at">По даті створення</option>
          <option value="updated_at">По даті обновлення</option>
        </select>
      </div>

      <div className="sort_item">
        <p>Напрямок:</p>
        <select value={order} onChange={handleOrderChange}>
          <option value="asc">По зростанню</option>
          <option value="desc">По спаданню</option>
        </select>
      </div>
    </div>
  );
};
