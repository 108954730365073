import React, { useState, useEffect } from "react";
import axios from "axios";

export const Filter = ({ onCrmChange }) => {
  const [crm, setCrm] = useState([]);

  const fetchCrm = () => {
    axios
      .get(`/api/table/crm`)
      .then((response) => {
        setCrm(response.data);
      })
      .catch((error) => console.error("Помилка:", error));
  };

  useEffect(() => {
    fetchCrm();
  }, []);

  const handleChange = (e) => {
    onCrmChange(e.target.value); // Передаём выбранную CRM наверх
  };

  return (
    <div className="sort_container">
      <div className="sort_item">
        <p>Фильтр по CRM:</p>
        <select onChange={handleChange}>
          <option value="">Усі</option>
          {crm.map((item) => (
            <option key={item.id} value={item.name}>
              {item.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
