import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { useNotification } from "../../NotificationModal/NotificationModal";

export const EditCrmForm = () => {
  const { id } = useParams(); // Получаем ID из URL
  const navigate = useNavigate();
  const { showNotification } = useNotification();

  const [crm, setCrm] = useState({
    name: "",
    description: "",
    reglament: "",
    url_video: "",
  });
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState("");
  const [created_at, setCreated_at] = useState("");
  const [updated_at, setUpdated_at] = useState("");

  useEffect(() => {
    axios
      .get(`/api/table/crm/${id}`)
      .then((response) => {
        setCrm(response.data);
        setContent(response.data.reglament);
        setCreated_at(response.data.created_at);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Помилка загрузки CRM:", error);
        setLoading(false);
      });
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCrm({ ...crm, [name]: value });
  };

  const handleChange = (value) => {
    setContent(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .put(`/api/edit_crm/${id}`, {
        ...crm,
        created_at: created_at,
        updated_at: updated_at,
        reglament: content,
      })
      .then(() => {
        navigate("/dashboard/crm");
        showNotification("CRM успішно оновлено!", "success");
      })
      .catch((error) => {
        console.error("Помилка оновлення CRM:", error);
        showNotification("Не вдалося оновити CRM!", "failed");
      });
  };

  if (loading) {
    return (
      <div className="container">
        <div className="loader">
          <ClipLoader size={100} color="red" />
        </div>
      </div>
    );
  }

  return (
    <div className="admin_main">
      <div className="container--big--admin">
        <div className="admin_main_title_back">
          <Link to="/dashboard/crm">
            <svg
              className="icon_back"
              width="24"
              height="16"
              viewBox="0 0 24 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M23 9C23.5523 9 24 8.55228 24 8C24 7.44772 23.5523 7 23 7V9ZM0.292892 7.29289C-0.0976315 7.68342 -0.0976315 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM23 7L1 7V9L23 9V7Z"></path>
            </svg>
          </Link>
          <h1>Редактировать CRM {id}</h1>
        </div>
        <div className="admin_main_content">
          <div className="admin_main_content_item">
            <form onSubmit={handleSubmit}>
              <div className="admin_main_content_form_input">
                <label htmlFor="name">Назва CRM</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={crm.name}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="admin_main_content_form_input">
                <label htmlFor="description">Опис</label>
                <textarea
                  id="description"
                  name="description"
                  value={crm.description}
                  onChange={handleInputChange}
                />
              </div>

              <div className="admin_main_content_form_input">
                <label htmlFor="reglament">Регламент</label>
                <ReactQuill
                  className="ql-editor-fixed"
                  value={content}
                  onChange={handleChange}
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, 3, false] }],
                      ["bold", "italic", "underline", "strike"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["link"],
                      [{ color: [] }, { background: [] }],
                      [{ align: [] }],
                      ["clean"],
                    ],
                  }}
                  formats={[
                    "header",
                    "bold",
                    "italic",
                    "underline",
                    "strike",
                    "list",
                    "bullet",
                    "link",

                    "align",
                    "color",
                    "background",
                  ]}
                  placeholder="Напишите регламент"
                />
              </div>

              <div className="admin_main_content_form_input">
                <label htmlFor="url_video">Посилання на відео</label>
                <input
                  type="text"
                  id="url_video"
                  name="url_video"
                  value={crm.url_video}
                  onChange={handleInputChange}
                  placeholder="https://"
                />
              </div>

              <button className="action_button" type="submit">
                Зберегти
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
