import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

import { useModal } from "../Modal/ModalContext";
import stylesModal from "../Modal/Modal.module.scss";

import { Gallery, Item } from "react-photoswipe-gallery";
import "photoswipe/dist/photoswipe.css";

import { CenterPanel } from "./Panels/CenterPanel";
import { RightPanel } from "./Panels/RightPanel";
import { ImportantInformation } from "./ImportantInformation/ImportantInformation";
import { ProductReglament } from "./ProductReglament/ProductReglament";

import ClipLoader from "react-spinners/ClipLoader";

import styles from "./Script.module.scss";

export const Product = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [products, setProducts] = React.useState([]);
  const [productId, setProductId] = React.useState(null);
  const [images, setImages] = React.useState([]);
  const { openModal } = useModal();
  const [loading, setLoading] = React.useState(true);
  const [importantInformation, setImportantInformation] = React.useState([]);
  const [productReglament, setProductReglament] = React.useState([]);
  const [urlProduct, setUrlProduct] = React.useState([]);
  const [urlVideo, setUrlVideo] = React.useState([]);
  const [fields, setFields] = React.useState([]);

  const token = localStorage.getItem("token_user");

  React.useEffect(() => {
    const fetchProducts = async () => {
      const token = localStorage.getItem("token_user");

      if (!token) {
        console.error("Токен відсутній. Перенаправлення на сторінку входу.");
        navigate("/login");
        return;
      }

      try {
        const response = await axios.get(`/api/get_product/${id}`, {
          headers: { "Authorization-User": `Bearer ${token}` },
        });
        setProducts(response.data);
        setProductId(response.data.id);
        setImportantInformation(response.data.important_information);
        setProductReglament(response.data.reglament);
        setUrlVideo(response.data.url_video);
        setUrlProduct(response.data.url);

        const imagesArray = response.data.images.split(",");
        const formattedImages = imagesArray.map((image) =>
          image.replace(/\\/g, "/")
        );
        setImages(formattedImages);
        setLoading(false);
      } catch (error) {
        console.error("Помилка загрузки продукта:", error);

        if (error.response) {
          if (error.response.status === 403) {
            setProducts(null);
          } else if (error.response.status === 401) {
            console.error(
              "Токен недійсний. Перенаправлення на сторінку входу."
            );
            navigate("/login");
          }
        }

        setLoading(false);
      }
    };
    fetchProducts();
  }, [id, navigate]);

  const handleOpenPhotoModal = (title) => {
    openModal(
      <div>
        <div className={stylesModal.modal__header}>
          <h2>{title}</h2>
        </div>
        <div className={stylesModal.modal__body}>
          <div>
            <div className={stylesModal.photo__content}>
              <Gallery
                options={{
                  bgOpacity: 0.9, // Прозрачность фона
                  spacing: 0.05, // Отступы
                  imageScaleMethod: "fit", // Альтернативный способ подгонки
                }}
              >
                <div className={stylesModal.imageWithFilter}>
                  <div className={stylesModal.PhotoModal_imageWithFilter}>
                    {images.map((image, index) => (
                      <Item
                        key={index}
                        original={
                          image.startsWith("http")
                            ? image
                            : `${process.env.REACT_APP_IP_SERVER}/${image}`
                        }
                        thumbnail={
                          image.startsWith("http")
                            ? image
                            : `${process.env.REACT_APP_IP_SERVER}/${image}`
                        }
                        width="1024"
                        height="768"
                      >
                        {({ ref, open }) => (
                          <img
                            ref={ref}
                            onClick={open}
                            src={
                              image.startsWith("http")
                                ? image
                                : `${process.env.REACT_APP_IP_SERVER}/${image}`
                            }
                            alt="PreviewPhoto"
                          />
                        )}
                      </Item>
                    ))}
                  </div>
                </div>
              </Gallery>
            </div>
          </div>
          <div>
            <h2 className={stylesModal.PhotoModal_video__title}>Відео</h2>
            <div className={stylesModal.PhotoModal_video__content}>
              <div className={stylesModal.PhotoModal_video__contentItem}>
                <iframe
                  width="560"
                  height="315"
                  src={urlVideo}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleOpenImportantInformation = () => {
    openModal(
      <ImportantInformation
        importantInformation={importantInformation}
        urlProduct={urlProduct}
        urlVideo={urlVideo}
      />
    );
  };

  const handleOpenProductReglament = () => {
    openModal(<ProductReglament productReglament={productReglament} />);
  };

  const goBack = () => {
    navigate(-1); // Возврат на предыдущую страницу
  };

  if (loading) {
    return (
      <>
        <div className="container">
          <div className="loader">
            <ClipLoader size={100} color="red" radius={100} />
          </div>
        </div>
      </>
    );
  }

  // Если продукт недоступен
  if (!loading && products === null) {
    return (
      <div className="container not-found">
        <h1>Немає доступу або продукт не знайдено</h1>
        <p>Зверніться до адміністратора</p>
      </div>
    );
  }

  return (
    <>
      <div className="script" id="script">
        <div className="container--big">
          <div className={styles.script__inner}>
            <div className={styles.script__btnBack}>
              <button onClick={goBack}>
                <svg
                  className={styles.script__btnBackIcon}
                  width="24"
                  height="16"
                  viewBox="0 0 24 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M23 9C23.5523 9 24 8.55228 24 8C24 7.44772 23.5523 7 23 7V9ZM0.292892 7.29289C-0.0976315 7.68342 -0.0976315 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM23 7L1 7V9L23 9V7Z"></path>
                </svg>
              </button>
              <h1 className={styles.script__title}>{products.name}</h1>
            </div>

            <div className={styles.script__blocks}>
              <div className={styles.script__body}>
                <div className={styles.script__main}>
                  <div className={styles.script__block}>
                    <div
                      className={styles.script__blockPhoto}
                      onClick={() => handleOpenPhotoModal("Фото")}
                    >
                      <img
                        src={`${process.env.REACT_APP_IP_SERVER}/${images[0]}`}
                        alt="script-img"
                      />
                    </div>
                    <div className={styles.script__blockInfo}>
                      <div className={styles.script__blockInfoCount}>
                        <span>1/{images.length}</span>
                      </div>
                      <ul className={styles.script__blockInfoList}>
                        <li className={styles.script__blockInfoListItem}>
                          <span className={styles.script__blockInfoListHeader}>
                            Ціна:
                          </span>
                          <span> {products.price} грн.</span>
                        </li>
                        <li className={styles.script__blockInfoListItem}>
                          <span className={styles.script__blockInfoListHeader}>
                            ID:
                          </span>
                          <span> {products.id_product_name} </span>
                        </li>
                        <li className={styles.script__blockInfoListItem}>
                          <span className={styles.script__blockInfoListHeader}>
                            URL:
                          </span>
                          <span>
                            {" "}
                            <a
                              className="url"
                              target="_blank"
                              href={products.url}
                            >
                              {products.url}
                            </a>
                          </span>
                        </li>
                      </ul>
                      <div className={styles.script__blockFunctions}>
                        <div className={styles.script__blockFunction}>
                          <button
                            className={styles.script__blockFunctionBtn}
                            onClick={handleOpenImportantInformation}
                          >
                            Важлива інформація
                          </button>
                        </div>
                        <div className={styles.script__blockFunction}>
                          <button
                            className={styles.script__blockFunctionBtn}
                            onClick={handleOpenProductReglament}
                          >
                            Регламент
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <CenterPanel id={productId} />
                </div>
              </div>
              <div className={styles.script__objections}>
                <h2 className={styles.script__objectionsTitle}>
                  Робота з запереченнями
                </h2>
                <RightPanel />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
