import React, { useState, useEffect } from "react";
import axios from "axios";
import { ClipLoader } from "react-spinners";

import { Link } from "react-router-dom";
import { useModal } from "../../Modal/ModalContext";
import styles from "../Projects.module.scss";

export const Education = () => {
  const { openModal } = useModal();
  const [educations, setEducations] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get("/api/educations")
      .then((res) => {
        setEducations(res.data);
        setLoading(false);
      })
      .catch((err) => console.error("Помилка загрузки даних:", err));
  }, []);

  const handleOpenModal = (education) => {
    openModal(
      <div className={styles.modalContent}>
        <div className={styles.modalEducationItem}>
          <div className={styles.modalImgWrapper}>
            <div
              className={styles.modalVideoPreview}
              onClick={() => handleOpenFullScreenModal(education.url_video)}
            >
              <img
                src={
                  education.url_video
                    ? `https://img.youtube.com/vi/${getYouTubeVideoId(
                        education.url_video
                      )}/hqdefault.jpg`
                    : "/path/to/default-placeholder.jpg"
                }
                alt="Video preview"
              />
            </div>
          </div>
          <div className={styles.modalEducationInfo}>
            <h3 className={styles.modalEducationTitle}>{education.title}</h3>
            <p
              className={styles.modalEducationDescription}
              dangerouslySetInnerHTML={{ __html: education.content }}
            ></p>
          </div>
        </div>
      </div>
    );
  };

  const handleOpenFullScreenModal = (videoUrl) => {
    if (videoUrl) {
      const embedUrl = videoUrl.replace("watch?v=", "embed/");
      openModal(
        <iframe
          className={styles.fullScreenVideo}
          src={embedUrl}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      );
    }
  };

  const getYouTubeVideoId = (url) => {
    if (!url) return null;
    const match = url.match(
      /(?:https?:\/\/)?(?:www\.)?youtube\.com\/embed\/([^?&]+)/
    );
    return match ? match[1] : null;
  };

  if (loading) {
    return (
      <div className="container">
        <div className="loader">
          <ClipLoader size={100} color="red" />
        </div>
      </div>
    );
  }

  return (
    <div className="page-section">
      <div className="container">
        <div className="projects__inner">
          <Link to="/projects" className={styles.scripts__backButton}>
            <svg
              className={styles.scripts__arrow}
              width="24"
              height="16"
              viewBox="0 0 24 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M23 9C23.5523 9 24 8.55228 24 8C24 7.44772 23.5523 7 23 7V9ZM0.292892 7.29289C-0.0976315 7.68342 -0.0976315 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM23 7L1 7V9L23 9V7Z"></path>
            </svg>
            <span style={{ textAlign: "center" }}>Навчання</span>
          </Link>
        </div>
        <div className={styles.education__inner}>
          {educations.map((education) => (
            <div
              key={education.id}
              className={styles.education__item}
              onClick={() => handleOpenModal(education)}
            >
              <div className={styles.img__wrapper}>
                <div className={styles.videoPreview}>
                  <img
                    src={
                      education.url_video
                        ? `https://img.youtube.com/vi/${getYouTubeVideoId(
                            education.url_video
                          )}/hqdefault.jpg`
                        : "/path/to/default-placeholder.jpg"
                    }
                    alt="Video preview"
                  />
                </div>
              </div>
              <div className={styles.education__info}>
                <h3 className={styles.education__title}>{education.title}</h3>
                <p
                  className={styles.education__description}
                  dangerouslySetInnerHTML={{ __html: education.content }}
                ></p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
