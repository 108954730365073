import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import axios from "axios";

import { HeaderAdmin } from "../HeaderAdmin/HeaderAdmin";
import { Modal } from "../Modal/Modal";
import { ModalProvider } from "../Modal/ModalContext";
import { NotificationProvider } from "../NotificationModal/NotificationModal";

import { AdminSettings } from "./AdminSettings";
import { CrmSettings } from "./CrmSettings/CrmSettings";
import { EditCrmForm } from "./CrmSettings/EditCrmForm";
import { ProductSettings } from "./ProductSettings/ProductSettings";
import { UserSettings } from "./UserSettings/UserSettings";
import { AddProductForm } from "./ProductSettings/AddProductForm";
import { EditProductForm } from "./ProductSettings/EditProductForm";
import { DublicateProductForm } from "./ProductSettings/DublicateProductForm";
import { SearchProvider } from "../Search/SearchContext";

export const Dashboard = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token_admin");

    if (!token) {
      navigate("/login-admin-88cjw2d0b1mx9iis2");
      return;
    }

    axios.defaults.headers.common["Authorization-Admin"] = `Bearer ${token}`;

    const checkToken = async () => {
      try {
        await axios.get("/api/protected", {
          headers: { "Authorization-Admin": `Bearer ${token}` },
        });
      } catch {
        localStorage.removeItem("token_admin");
        navigate("/login-admin-88cjw2d0b1mx9iis2");
      }
    };

    checkToken();
  }, [navigate]);

  return (
    <>
      <SearchProvider>
        <ModalProvider>
          <NotificationProvider>
            <HeaderAdmin />
            <div>
              <Routes>
                <Route path="/" element={<AdminSettings />} />
                <Route path="/crm" element={<CrmSettings />} />
                <Route path="/products" element={<ProductSettings />} />
                <Route path="/users" element={<UserSettings />} />
                <Route path="/products/add" element={<AddProductForm />} />
                <Route
                  path="/products/duplicate/:id"
                  element={<DublicateProductForm />}
                />
                <Route
                  path="/products/edit/:id"
                  element={<EditProductForm />}
                />
                <Route path="/crm/edit/:id" element={<EditCrmForm />} />
              </Routes>
              <Modal />
            </div>
          </NotificationProvider>
        </ModalProvider>
      </SearchProvider>
    </>
  );
};
