import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { useNotification } from "../../NotificationModal/NotificationModal";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ProductFields } from "./ProductFields";

export const DublicateProductForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [idProductName, setIdProductName] = useState("");
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");

  // Храним новые (локальные) файлы в массиве images
  const [images, setImages] = useState([]);
  // Храним все превью (старые и новые), чтобы отображать в интерфейсе
  const [imagePreviews, setImagePreviews] = useState([]);

  const [crmList, setCrmList] = useState([]);
  const [selectedCrm, setSelectedCrm] = useState([]);

  const [created_at, setCreated_at] = useState("");
  const [updated_at, setUpdated_at] = useState("");

  const [important_information, setImportantInformation] = useState("");
  const [reglament, setReglament] = useState("");

  const [url, setUrl] = useState("");
  const [urlVideo, setUrlVideo] = useState("");

  const { showNotification } = useNotification();

  useEffect(() => {
    // Загружаем данные товара
    axios.get(`/api/table/product/${id}`).then((response) => {
      const data = response.data;
      setIdProductName(data.id_product_name);
      setName(data.name);
      setPrice(data.price);
      setSelectedCrm(data.crm_ids ? data.crm_ids.split(",").map(Number) : []);

      setCreated_at(data.created_at);
      setUpdated_at(data.updated_at);
      setImportantInformation(data.important_information);
      setReglament(data.reglament);
      setUrl(data.url);
      setUrlVideo(data.url_video);

      // Старые изображения (пути на сервере)
      const existingPaths = data.images ? data.images.split(",") : [];
      // Добавляем их в imagePreviews, чтобы отрисовать в интерфейсе
      setImagePreviews(existingPaths);

      setLoading(false);
    });

    // Загружаем список CRM
    axios.get("/api/table/crm").then((response) => {
      setCrmList(response.data);
      setLoading(false);
    });
  }, [id]);

  // === ДОБАВЛЕНО ===
  // Универсальная функция для добавления файлов (как из input, так и из буфера обмена)
  const addFiles = (files) => {
    // Проверяем лимит в 12 изображений, учитывая уже существующие (старые + новые)
    if (imagePreviews.length + files.length > 12) {
      alert("Максимум 12 зображень!");
      return;
    }

    // Создаём blob-ссылки для отображения превью
    const newPreviews = files.map((file) => URL.createObjectURL(file));

    // Дополняем массив превью
    setImagePreviews((prev) => [...prev, ...newPreviews]);
    // Дополняем массив новых файлов
    setImages((prev) => [...prev, ...files]);
  };

  // === ДОБАВЛЕНО ===
  // Обработчик paste (вставка из буфера обмена)
  useEffect(() => {
    const handlePaste = (event) => {
      const clipboardItems = event.clipboardData?.items || [];
      const newFiles = [];

      for (let i = 0; i < clipboardItems.length; i++) {
        const item = clipboardItems[i];
        // Проверяем, является ли этот элемент изображением
        if (item.type.indexOf("image") === 0) {
          const file = item.getAsFile();
          if (file) {
            newFiles.push(file);
          }
        }
      }

      if (newFiles.length > 0) {
        event.preventDefault(); // чтобы не «вставлялся» ненужный контент в поля
        addFiles(newFiles);
      }
    };

    document.addEventListener("paste", handlePaste);
    return () => {
      document.removeEventListener("paste", handlePaste);
    };
  }, [imagePreviews]);
  // Можно также указать [imagePreviews, images], но достаточно и такого варианта.

  const handleChangeImportantInformation = (value) =>
    setImportantInformation(value || "");
  const handleChangeReglament = (value) => setReglament(value || "");
  const handleChangeUrl = (e) => setUrl(e.target.value || "");
  const handleChangeUrlVideo = (e) => setUrlVideo(e.target.value || "");

  // Обработка выбора файлов через input
  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    addFiles(files);
  };

  // Удаление изображения из списка
  const handleImageRemove = (index) => {
    const newPreviews = [...imagePreviews];
    newPreviews.splice(index, 1);
    setImagePreviews(newPreviews);

    // Удаляем из массива "images" соответствующий файл (если он там есть).
    // Если удаляем старое изображение (у которого превью не blob:),
    // то в массиве images его может и не быть.
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
  };

  // Выбор CRM
  const handleCrmChange = (crmId, isChecked) => {
    setSelectedCrm((prev) =>
      isChecked ? [...prev, crmId] : prev.filter((id) => id !== crmId)
    );
  };

  // Отправка формы
  const handleSubmit = (e) => {
    e.preventDefault();
    const idProductName = e.target.id_product_name.value.trim();
    const name = e.target.name.value.trim();
    const price = e.target.price.value.trim();
    const url = e.target.url?.value.trim() || "";
    const urlVideo = e.target.url_video?.value.trim() || "";

    if (!name || !price) {
      alert("Введите название и цену продукта!");
      return;
    }

    // Проверяем, выбрана ли хотя бы одна CRM
    if (selectedCrm.length === 0) {
      alert("Необхідно вибрати хоча б одну CRM систему!");
      return;
    }

    const formData = new FormData();
    formData.append("id", id);
    formData.append("id_product_name", idProductName);
    formData.append("name", name);
    formData.append("price", price);
    formData.append("important_information", important_information);
    formData.append("reglament", reglament);
    formData.append("url", url);
    formData.append("url_video", urlVideo);
    formData.append("created_at", created_at);
    formData.append("updated_at", updated_at);

    // Старые изображения (в imagePreviews те, которые не blob:)
    const oldImages = imagePreviews
      .filter((preview) => !preview.startsWith("blob:")) // только «старые» пути
      .map((preview) =>
        preview.replace(`${process.env.REACT_APP_IP_SERVER}/`, "")
      );
    formData.append("existingImages", JSON.stringify(oldImages));

    // Новые файлы (хранятся в images)
    images.forEach((file) => {
      formData.append("images", file);
    });

    selectedCrm.forEach((crmId) => {
      formData.append("crmIds[]", crmId);
    });

    axios
      .post(`/api/dublicate_product`, formData)
      .then(() => {
        navigate("/dashboard/products");
        showNotification("Дубликат успішно додано!", "success");
      })
      .catch((error) => {
        console.error("Помилка дублювання товару:", error);
        showNotification("Не вдалося додати дубликат!", "failed");
      });
  };

  if (loading)
    return (
      <div className="container">
        <div className="loader">
          <ClipLoader size={100} color="red" radius={100} />
        </div>
      </div>
    );

  return (
    <div className="admin_main">
      <div className="container--big--admin">
        <div className="admin_main_title_back">
          <Link to="/dashboard/products">
            <svg
              className="icon_back"
              width="24"
              height="16"
              viewBox="0 0 24 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M23 9C23.5523 9 24 8.55228 24 8C24 7.44772 23.5523 7 23 7V9ZM0.292892 7.29289C-0.0976315 7.68342 -0.0976315 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM23 7L1 7V9L23 9V7Z"></path>
            </svg>
          </Link>
          <h1>Дублювати товар</h1>
        </div>

        <div className="admin_main_content">
          <div className="admin_main_content_form">
            <div className="admin_main_content_form_form">
              <form onSubmit={handleSubmit}>
                <div className="admin_main_content_form_input">
                  <label htmlFor="name">Назва товару</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    defaultValue={name || ""}
                    required
                  />
                </div>
                <div className="admin_main_content_form_input">
                  <label htmlFor="price">Ціна (грн)</label>
                  <input
                    type="number"
                    id="price"
                    name="price"
                    defaultValue={price || ""}
                    required
                  />
                </div>
                <div className="admin_main_content_form_input">
                  <label htmlFor="id_product_name">ID продукта</label>
                  <input
                    type="text"
                    id="id_product_name"
                    name="id_product_name"
                    defaultValue={idProductName}
                    required
                  />
                </div>

                <div className="admin_main_content_form_input">
                  <label>ВАЖЛИВА ІНФОРМАЦІЯ</label>
                  <ReactQuill
                    className="ql-editor-fixed"
                    value={important_information}
                    onChange={handleChangeImportantInformation}
                    modules={{
                      toolbar: [
                        [{ header: [1, 2, 3, false] }],
                        ["bold", "italic", "underline", "strike"],
                        [{ list: "ordered" }, { list: "bullet" }],
                        ["link"],
                        [{ color: [] }, { background: [] }],
                        [{ align: [] }],
                        ["clean"],
                      ],
                    }}
                    formats={[
                      "header",
                      "bold",
                      "italic",
                      "underline",
                      "strike",
                      "list",
                      "bullet",
                      "link",
                      "align",
                      "color",
                      "background",
                    ]}
                  />
                </div>

                <div className="admin_main_content_form_input">
                  <label>РЕГЛАМЕНТ</label>
                  <ReactQuill
                    className="ql-editor-fixed"
                    value={reglament}
                    onChange={handleChangeReglament}
                    modules={{
                      toolbar: [
                        [{ header: [1, 2, 3, false] }],
                        ["bold", "italic", "underline", "strike"],
                        [{ list: "ordered" }, { list: "bullet" }],
                        ["link"],
                        [{ color: [] }, { background: [] }],
                        [{ align: [] }],
                        ["clean"],
                      ],
                    }}
                    formats={[
                      "header",
                      "bold",
                      "italic",
                      "underline",
                      "strike",
                      "list",
                      "bullet",
                      "link",
                      "align",
                      "color",
                      "background",
                    ]}
                  />
                </div>

                <div className="admin_main_content_form_input">
                  <label htmlFor="url">URL</label>
                  <input
                    type="text"
                    id="url"
                    name="url"
                    value={url}
                    onChange={handleChangeUrl}
                  />
                </div>
                <div className="admin_main_content_form_input">
                  <label htmlFor="url_video">URL відео</label>
                  <input
                    type="text"
                    id="url_video"
                    name="url_video"
                    value={urlVideo}
                    onChange={handleChangeUrlVideo}
                  />
                </div>

                <div className="admin_main_content_form_input">
                  <label>CRM:</label>
                  <div className="admin_main_content_form_checkbox_container">
                    {crmList.map((crm) => (
                      <div
                        key={crm.id}
                        className="admin_main_content_form_checkbox"
                      >
                        <input
                          type="checkbox"
                          id={`crm-${crm.id}`}
                          value={crm.id}
                          checked={selectedCrm.includes(crm.id)}
                          onChange={(e) =>
                            handleCrmChange(crm.id, e.target.checked)
                          }
                        />
                        <label htmlFor={`crm-${crm.id}`}>{crm.name}</label>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="admin_main_content_form_input">
                  <label htmlFor="images">Зображення</label>
                  <input
                    type="file"
                    id="images"
                    multiple
                    accept="image/*"
                    onChange={handleImageUpload}
                  />
                  <div className="image-previews">
                    {imagePreviews.map((preview, index) => (
                      <div key={index} className="image-preview">
                        <img
                          src={
                            preview.startsWith("blob:")
                              ? preview // blob-ссылка (новое изображение)
                              : `${process.env.REACT_APP_IP_SERVER}/${preview}` // старое изображение с сервера
                          }
                          alt={`Preview ${index}`}
                        />
                        <button
                          type="button"
                          onClick={() => handleImageRemove(index)}
                        >
                          X
                        </button>
                      </div>
                    ))}
                  </div>
                </div>

                <button className="action_button" type="submit">
                  Дублювати товар
                </button>
              </form>
            </div>
            <div className="admin_main_content_form_panel_scripts">
              <ProductFields productId={id} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
