import React from "react";
import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { LoginAdmin } from "./components/LoginAdmin/LoginAdmin";
import { Login } from "./components/Login/Login";
import { Dashboard } from "./components/Dashboard/Dashboard";
import { Projects } from "./components/Projects/Projects";
import { NotFound } from "./components/404/404";
function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/login-admin-88cjw2d0b1mx9iis2"
            element={<LoginAdmin />}
          />
          <Route path="/dashboard/*" element={<Dashboard />} />
          <Route path="/projects/*" element={<Projects />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
