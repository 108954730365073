import React from "react";
import styles from "./ToggleTheme.module.scss";

export const ToggleTheme = () => {
  // Инициализация темы из LocalStorage или установка по умолчанию
  const [theme, setTheme] = React.useState(() => {
    return (
      localStorage.getItem("theme") || localStorage.setItem("theme", "light")
    );
  });

  // Применяем тему при монтировании компонента
  React.useEffect(() => {
    document.documentElement.setAttribute("class", theme);
  }, [theme]);

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme); // Сохраняем тему в LocalStorage
  };

  return (
    <div className={styles.themeInput__container}>
      <input
        id="themeInput"
        name="themeInput"
        type="checkbox"
        className={styles.themeInput}
        checked={theme === "light"}
        onChange={toggleTheme}
      />
      <label htmlFor="themeInput" className={styles.themeInput__label}>
        <span></span>
      </label>
    </div>
  );
};
