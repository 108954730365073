import React from "react";
import axios from "axios";
import { useModal } from "../../Modal/ModalContext";
import { useNotification } from "../../NotificationModal/NotificationModal";

export const EditPassUserForm = ({ user, refreshData }) => {
  const [password, setPassword] = React.useState("");
  const { closeModal } = useModal();
  const { showNotification } = useNotification();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!password) {
      alert("Пароль не может бути пустим!");
      return;
    }

    axios
      .put(`/api/edit_pass_user/${user.id}`, {
        password,
      })
      .then(() => {
        closeModal();
        refreshData();
        showNotification("Пароль успішно оновлено!", "success");
      })
      .catch((error) => {
        console.error("Помилка обновлення:", error);
        showNotification("Не вдалося оновити пароль!", "failed");
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Редагувати пароль</h2>
      <div className="admin_main_content_form_input">
        <label>
          Новий пароль:<span className="star__red">*</span>
        </label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
      </div>
      <button className="action_button" type="submit">
        Зберегти
      </button>
    </form>
  );
};
