import React from "react";
import styles from "./ImportantInformation.module.scss";
import modalStyles from "../../Modal/Modal.module.scss";

export const ImportantInformation = ({
  importantInformation,
  urlProduct,
  urlVideo,
}) => {
  return (
    <>
      <div>
        <h1 className={styles.ImportantInformation_main_title}>
          Важлива інформація
        </h1>

        <div
          className={[
            styles.ImportantInformation_main_content,
            modalStyles.modal__body,
          ].join(" ")}
        >
          <div>
            {importantInformation !== "" && (
              <div className={styles.ImportantInformation_wrap}>
                <div
                  className={styles.ImportantInformation_wrap_content}
                  dangerouslySetInnerHTML={{ __html: importantInformation }}
                />
                {urlVideo !== "" && (
                  <div className={styles.ImportantInformation_wrap_video}>
                    <iframe
                      className={styles.ImportantInformation_video}
                      src={urlVideo}
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                  </div>
                )}
              </div>
            )}
            {importantInformation === "" && (
              <div className={styles.ImportantInformation_wrap}>
                Поки що немає жодного розділу
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
