import React from "react";
import axios from "axios";

import "../../Accordion/Accordion.css";

export const CenterPanel = ({ id }) => {
  const [activeTabs, setActiveTabs] = React.useState([]);
  const [fields, setFields] = React.useState([]);
  const contentRefs = React.useRef({});

  const toggleTab = (panelId) => {
    setActiveTabs((prev) =>
      prev.includes(panelId)
        ? prev.filter((tab) => tab !== panelId)
        : [...prev, panelId]
    );
  };

  React.useEffect(() => {
    const fetchFields = async () => {
      try {
        const response = await axios.get(`/api/product_fields/${id}/fields`);
        setFields(response.data);
      } catch (error) {
        console.error("Помилка загрузки полей:", error);
      }
    };
    fetchFields();
  }, [id]);

  if (!fields) {
    return <p>Загрузка даних...</p>;
  }

  return (
    <div>
      <ul>
        {fields.map((field) => (
          <li key={field.id}>
            <div className="panel">
              <div className="panel__title" onClick={() => toggleTab(field.id)}>
                <button
                  className="panel__label"
                  role="tab"
                  aria-expanded={activeTabs.includes(field.id)}
                >
                  {field.label}
                </button>
                <svg
                  className="panel__icon"
                  style={{
                    transform: activeTabs.includes(field.id)
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                    transition: "transform 0.3s ease",
                  }}
                  width="13"
                  height="7"
                  viewBox="0 0 13 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L5.52778 5.59317C6.0625 6.13561 6.9375 6.13561 7.47222 5.59317L12 1"
                    strokeWidth="1.2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </div>

              <div
                className="panel__inner"
                style={{
                  height: activeTabs.includes(field.id)
                    ? `${contentRefs.current[field.id]?.scrollHeight || 0}px`
                    : "0px",
                  overflow: "hidden",
                  transition: "height 0.3s ease",
                }}
                aria-hidden={!activeTabs.includes(field.id)}
              >
                <div
                  className="panel__content"
                  ref={(el) => (contentRefs.current[field.id] = el)}
                  dangerouslySetInnerHTML={{ __html: field.content }}
                ></div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
