import React, { useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useModal } from "../../Modal/ModalContext";
import { AddCrmForm } from "./AddCrmForm";
import { SearchContext } from "../../Search/SearchContext";
import { Sort } from "../../Sort/Sort";
import ClipLoader from "react-spinners/ClipLoader";
import { useNotification } from "../../NotificationModal/NotificationModal";

export const CrmSettings = () => {
  const tableName = "crm";
  const [data, setData] = React.useState([]);
  const { searchQuery } = useContext(SearchContext);
  const [loading, setLoading] = React.useState(true);
  const { showNotification } = useNotification();

  React.useEffect(() => {
    fetchCrm();
  }, []);

  const { openModal } = useModal();

  const fetchCrm = () => {
    axios
      .get(`/api/table/${tableName}`)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => console.error("Помилка:", error));
  };

  const fetchSortedData = (sort, order) => {
    axios
      .get(`/api/sort_crm?sort=${sort}&order=${order}`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Помилка отримання даних:", error);
      });
  };

  const handleAddCrm = () => {
    openModal(<AddCrmForm refreshData={() => fetchCrm()} />);
  };

  const handleDeleteCrm = (crmId) => {
    const isConfirmed = window.confirm(
      "Ви впевнені, що хочете видалити цей CRM?"
    );
    if (!isConfirmed) return;

    axios
      .delete(`/api/delete_crm/${crmId}`)
      .then(() => {
        fetchCrm();
        showNotification("CRM успішно видалено!", "success");
      })
      .catch((error) => {
        console.error("Помилка видалення:", error);
        showNotification("Не вдалося видалити CRM!", "failed");
      });
  };

  const filteredCrm = data.filter((crm) =>
    crm.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (loading) {
    return (
      <>
        <div className="container">
          <div className="loader">
            <ClipLoader size={100} color="red" radius={100} />
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="admin_main">
      <div className="container--big--admin">
        <div className="admin_main_title">
          <h1>CRM</h1>
          <button className="admin_main_title_button" onClick={handleAddCrm}>
            Додати CRM
          </button>
        </div>
        <Sort fetchSortedData={fetchSortedData} />
        <div className="admin_main_content">
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Назва CRM</th>
                <th>Опис CRM</th>
                <th>Регламент</th>
                <th>Дата створення</th>
                <th>Дата оновлення</th>
                <th className="th_edit"></th>
                <th className="th_delete"></th>
              </tr>
            </thead>
            <tbody>
              {filteredCrm.map((item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>{item.description}</td>
                  <td>
                    {item.reglament.length > 0 ? "Регламент присутній" : ""}
                  </td>
                  <td>{item.created_at}</td>
                  <td>{item.updated_at}</td>
                  <td className="actions th_edit">
                    <Link
                      className="edit"
                      to={`/dashboard/crm/edit/${item.id}`}
                    >
                      Редагувати
                    </Link>
                  </td>
                  <td className="actions th_delete">
                    <button
                      className="delete"
                      onClick={() => handleDeleteCrm(item.id)}
                    >
                      Видалити
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
