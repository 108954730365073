import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import { SearchContext } from "../../Search/SearchContext";
import ClipLoader from "react-spinners/ClipLoader";
import { SortProduct } from "../../Sort/SortProduct";
import { Filter } from "../../Filter/Filter";
import { useNotification } from "../../NotificationModal/NotificationModal";
import { Paginator } from "../../Paginator/Paginator";

export const ProductSettings = () => {
  const [products, setProducts] = useState([]);
  const { searchQuery } = useContext(SearchContext);
  const [loading, setLoading] = useState(true);
  const { showNotification } = useNotification();

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const [selectedCrm, setSelectedCrm] = useState(""); // Храним выбранную CRM

  useEffect(() => {
    axios
      .get("/api/table/products")
      .then((response) => {
        setProducts(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Помилка загрузки продуктів:", error);
      });
  }, []);

  const handleDeleteProduct = (productId) => {
    const isConfirmed = window.confirm(
      "Ви впевнені, що хочете видалити цей продукт?"
    );
    if (!isConfirmed) return;

    axios
      .delete(`/api/delete_product/${productId}`)
      .then(() => {
        fetchProduct();
        showNotification("Товар успішно видалено!");
      })
      .catch((error) => console.error("Помилка видалення:", error));
  };

  const fetchProduct = () => {
    axios
      .get(`/api/table/products`)
      .then((response) => setProducts(response.data))
      .catch((error) => console.error("Помилка:", error));
  };

  const fetchSortedData = (sort, order) => {
    axios
      .get(`/api/sort_product?sort=${sort}&order=${order}`)
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => {
        console.error("Помилка отримання даних:", error);
      });
  };

  const handleCrmFilterChange = (crmName) => {
    setSelectedCrm(crmName); // Обновляем выбранную CRM
  };

  // Фильтруем товары по CRM и строке поиска
  // Фильтруем товары по CRM и строке поиска
  const filteredProducts = products.filter((product) => {
    const matchesCrm =
      !selectedCrm ||
      (product.crm_names && product.crm_names.includes(selectedCrm));

    const matchesSearch =
      product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      product.id_product_name
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase());

    return matchesCrm && matchesSearch;
  });

  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);
  const paginatedProducts = filteredProducts.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  if (loading) {
    return (
      <div className="container">
        <div className="loader">
          <ClipLoader size={100} color="red" radius={100} />
        </div>
      </div>
    );
  }

  return (
    <div className="admin_main">
      <div className="container--big--admin">
        <div className="admin_main_title">
          <h1>Товари</h1>
          <Link
            to="/dashboard/products/add"
            className="admin_main_title_button"
          >
            Додати товар
          </Link>
        </div>
        <div className="filter_sort_wrap">
          <Filter onCrmChange={handleCrmFilterChange} />
          <SortProduct fetchSortedData={fetchSortedData} />
        </div>
        <div className="admin_main_content">
          <table>
            <thead>
              <tr>
                <th>Photo</th>
                <th>ID продукта</th>
                <th>Ім'я</th>
                <th>Ціна</th>
                <th>CRM</th>
                <th>Дата створення</th>
                <th>Дата оновлення</th>
                <th className="th_duplicate"></th>
                <th className="th_edit"></th>
                <th className="th_delete"></th>
              </tr>
            </thead>
            <tbody>
              {paginatedProducts.map((product) => (
                <tr key={product.id}>
                  <td className="list_item_image">
                    {product.image_url ? (
                      <img
                        src={`${process.env.REACT_APP_IP_SERVER}/${product.image_url}`}
                        alt={product.name}
                        className="product-image"
                      />
                    ) : (
                      <span>No image</span>
                    )}
                  </td>
                  <td>{product.id_product_name}</td>
                  <td>{product.name}</td>
                  <td>{product.price}</td>
                  <td>{product.crm_names || "Нет связанных CRM"}</td>
                  <td>{product.created_at}</td>
                  <td>{product.updated_at}</td>
                  <td className="actions th_duplicate">
                    <Link
                      to={`/dashboard/products/duplicate/${product.id}`}
                      className="duplicate"
                    >
                      Дублювати
                    </Link>
                  </td>
                  <td className="actions th_edit">
                    <Link
                      to={`/dashboard/products/edit/${product.id}`}
                      className="edit"
                    >
                      Редагувати
                    </Link>
                  </td>
                  <td className="actions th_delete">
                    <button
                      className="delete"
                      onClick={() => handleDeleteProduct(product.id)}
                    >
                      Видалити
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {totalPages > 1 && (
          <Paginator
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
          />
        )}
      </div>
    </div>
  );
};
