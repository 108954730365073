import React, { useState } from "react";
import Panel from "./Panel";
import "./Accordion.css";

const Accordion = ({ panels }) => {
  const [activeTabs, setActiveTabs] = useState([]);

  const toggleTab = (index) => {
    setActiveTabs((prev) =>
      prev.includes(index)
        ? prev.filter((tab) => tab !== index)
        : [...prev, index]
    );
  };

  return (
    <div className="accordion" role="tablist">
      {panels.map((panel, index) => (
        <Panel
          key={index}
          label={panel.label}
          content={panel.content}
          isActive={activeTabs.includes(index)}
          onClick={() => toggleTab(index)}
        />
      ))}
    </div>
  );
};

export default Accordion;
