import React, { useEffect, useState, useContext } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { SearchContext } from "../Search/SearchContext";
import { useModal } from "../Modal/ModalContext";
import { CrmReglament } from "./CrmReglament/CrmReglament";
import { Paginator } from "../Paginator/Paginator"; // Подключаем пагинатор
import axios from "axios";
import styles from "./Projects.module.scss";

export const ProductList = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const { searchQuery } = useContext(SearchContext);
  const { openModal } = useModal();
  const [crmReglament, setCrmReglament] = useState("");
  const [crmReglamentVideo, setCrmReglamentVideo] = useState("");

  // Пагинация
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchProducts = async () => {
      const token = localStorage.getItem("token_user");

      if (!token) {
        navigate("/login");
        return;
      }

      try {
        const response = await axios.get(`/api/crm/${id}/products`, {
          headers: { "Authorization-User": `Bearer ${token}` },
        });
        setProducts(response.data);

        setLoading(false);
      } catch (error) {
        console.error("Помилка загрузки продуктів:", error);
        if (error.response) {
          if (error.response.status === 403) {
            setProducts(null);
            setLoading(false);
          } else if (error.response.status === 401) {
            console.error(
              "Токен недійсний. Перенаправлення на сторінку входу."
            );
            navigate("/login");
          }
        }
      }
    };

    const fetchCrmReglament = async () => {
      const token = localStorage.getItem("token_user");
      if (!token) {
        console.error("Токен відсутній");
        return;
      }

      try {
        const response = await axios.get(`/api/crm/${id}/reglament`, {
          headers: { "Authorization-User": `Bearer ${token}` }, // Передаём токен пользователя
        });
        setCrmReglamentVideo(response.data.url_video);
        setCrmReglament(response.data.reglament);
      } catch (error) {
        console.error("Помилка отримання регламенту CRM:", error);
      }
    };

    fetchProducts();
    fetchCrmReglament();
  }, [id, navigate]);

  const handleOpenCrmReglament = () => {
    openModal(
      <CrmReglament
        crmReglament={crmReglament}
        crmReglamentVideo={crmReglamentVideo}
      />
    );
  };

  if (loading) {
    return (
      <div className="container">
        <div className="loader">
          <ClipLoader size={100} color="red" radius={100} />
        </div>
      </div>
    );
  }

  // Если продукт недоступен
  if (!loading && products === null) {
    return (
      <div className="container not-found">
        <h1>Немає доступу або продукт не знайдено</h1>
        <p>Зверніться до адміністратора</p>
      </div>
    );
  }

  const filteredProducts = products.filter(
    (product) =>
      product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      product.id_product_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);
  const paginatedProducts = filteredProducts.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div id="scripts" className={[styles.scripts, "page-section"].join(" ")}>
      <div className="container">
        <div className={styles.scripts__inner}>
          <Link to="/projects" className={styles.scripts__backButton}>
            <svg
              className={styles.scripts__arrow}
              width="24"
              height="16"
              viewBox="0 0 24 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M23 9C23.5523 9 24 8.55228 24 8C24 7.44772 23.5523 7 23 7V9ZM0.292892 7.29289C-0.0976315 7.68342 -0.0976315 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM23 7L1 7V9L23 9V7Z"></path>
            </svg>
            <span>Назад</span>
          </Link>
          <button
            className={styles.scripts__regulationsButton}
            type="button"
            onClick={handleOpenCrmReglament}
          >
            Регламент
          </button>
          <div className={styles.scripts__list}>
            <ul>
              {paginatedProducts.length > 0 ? (
                paginatedProducts.map((product) => (
                  <li key={product.id}>
                    <Link
                      className={styles.scripts__item}
                      to={`/projects/product/${product.url_product}`}
                    >
                      <div className={styles.scripts__wrapper}>
                        <img
                          src={`${process.env.REACT_APP_IP_SERVER}/${product.image_url}`}
                          alt={product.name}
                          className={styles.scripts__img}
                        />
                      </div>
                      <div className={styles.scripts__info}>
                        <h3 className={styles.scripts__title}>
                          {product.name}
                        </h3>
                        <ul>
                          <li className={styles.scripts__listItem}>
                            <span className={styles.scripts__listHeader}>
                              Ціна:
                            </span>{" "}
                            <span className={styles.scripts__listValue}>
                              {product.price} Грн.
                            </span>
                          </li>
                          <li className={styles.scripts__listItem}>
                            <span className={styles.scripts__listHeader}>
                              ID:
                            </span>{" "}
                            <span className={styles.scripts__listValue}>
                              {product.id_product_name}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </Link>
                  </li>
                ))
              ) : (
                <p>Немає зв'язаних продуктів</p>
              )}
            </ul>
          </div>
          {totalPages > 1 && (
            <Paginator
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={setCurrentPage}
            />
          )}
        </div>
      </div>
    </div>
  );
};
