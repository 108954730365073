import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNotification } from "../../NotificationModal/NotificationModal";

export const EditAdminUser = () => {
  const [login, setLogin] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [passwordErrors, setPasswordErrors] = useState({
    length: false,
    upperCase: false,
    lowerCase: false,
    number: false,
    latinLetters: false, // Добавляем новое поле для проверки латинских букв
  });
  const { showNotification } = useNotification();

  useEffect(() => {
    const fetchAdminProfile = async () => {
      const token = localStorage.getItem("token_admin");

      if (!token) {
        console.error("Токен не знайдено");
        return;
      }

      try {
        const response = await axios.get("/api/admin/profile", {
          headers: { "Authorization-Admin": `Bearer ${token}` },
        });

        const { user } = response.data;

        if (user) {
          setLogin(user.name);
          setEmail(user.email);
        } else {
          console.error("Профіль адміністратора не знайдено");
        }
      } catch (error) {
        console.error("Помилка при отриманні профілю адміністратора:", error);
      }
    };

    fetchAdminProfile();
  }, []);

  const generatePassword = () => {
    const length = 12;
    const lowerCase = "abcdefghijklmnopqrstuvwxyz";
    const upperCase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const numbers = "0123456789";
    const symbols = "!@#$%^&*()_+[]{}|;:,.<>?";
    const allChars = lowerCase + upperCase + numbers + symbols;

    const getRandomChar = (chars) =>
      chars[Math.floor(Math.random() * chars.length)];
    let password =
      getRandomChar(lowerCase) +
      getRandomChar(upperCase) +
      getRandomChar(numbers) +
      getRandomChar(symbols);

    for (let i = password.length; i < length; i++) {
      password += getRandomChar(allChars);
    }

    password = password
      .split("")
      .sort(() => Math.random() - 0.5)
      .join("");
    setPassword(password);
    validatePassword(password);
  };

  const validatePassword = (password) => {
    const errors = {
      length: password.length < 8,
      upperCase: !/[A-Z]/.test(password),
      lowerCase: !/[a-z]/.test(password),
      number: !/[0-9]/.test(password),
      latinLetters: !/^[A-Za-z0-9!@#$%^&*()_+[\]{}|;:,.<>?]*$/.test(password), // Проверка на латинские буквы
    };
    setPasswordErrors(errors);
    return !Object.values(errors).some((error) => error);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
  };

  const handleSubmitChangeLoginEmail = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token_admin");

    if (!login || !email) {
      console.error("Логін або email не заповнені");
      return;
    }

    try {
      await axios.put(
        "/api/admin/edit_profile",
        { name: login, email },
        {
          headers: { "Authorization-Admin": `Bearer ${token}` },
        }
      );
      showNotification("Дані успішно оновлено", "success");
    } catch (error) {
      console.error("Помилка оновлення даних:", error.response?.data);
      showNotification("Не вдалося оновити дані", "failed");
    }
  };

  const handleSubmitChangePassword = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token_admin");

    if (password !== passwordRepeat) {
      showNotification("Пароли не співпадають", "failed");
      return;
    }

    if (!validatePassword(password)) {
      showNotification("Пароль не відповідає вимогам", "failed");
      return;
    }

    try {
      await axios.put(
        "/api/admin/edit_password",
        { password },
        {
          headers: { "Authorization-Admin": `Bearer ${token}` },
        }
      );
      showNotification("Пароль успішно оновлено", "success");
      setPassword("");
      setPasswordRepeat("");
    } catch (error) {
      console.error("Помилка оновлення пароля:", error.response.data);
    }
  };

  return (
    <div className="edit_admin_block">
      <div className="edit_admin_block_content">
        <div className="edit_admin_block_content_item">
          <form className="admin_wrap" onSubmit={handleSubmitChangeLoginEmail}>
            <h3>Змінити логін та email</h3>
            <div className="admin_wrap_label">
              <label htmlFor="email">Email</label>
              <input
                type="text"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="admin_wrap_label">
              <label htmlFor="login">Логін</label>
              <input
                type="text"
                name="login"
                value={login}
                onChange={(e) => setLogin(e.target.value)}
              />
            </div>
            <div className="admin_wrap_buttons">
              <input type="submit" value="Змінити логін та email" />
            </div>
          </form>
          <hr style={{ marginTop: "40px", color: "#000" }} />
          <form
            style={{ marginTop: "40px" }}
            className="admin_wrap"
            onSubmit={handleSubmitChangePassword}
          >
            <h3>Змінити пароль</h3>
            <div className="admin_wrap_label admin_wrap_label_repeat">
              <label htmlFor="password">Новий пароль</label>
              <div className="div">
                <input
                  type="text"
                  name="password"
                  value={password}
                  onChange={handlePasswordChange}
                  style={{
                    borderColor: Object.values(passwordErrors).some(
                      (error) => error
                    )
                      ? "var(--damage-text-color)"
                      : "green",
                  }}
                />
                <div
                  className="edit generate_password_button"
                  onClick={generatePassword}
                >
                  Згенерувати пароль
                </div>
              </div>
              {passwordErrors.length && (
                <p className="generate_password_text">
                  Пароль повинен бути не менше 8 символів
                </p>
              )}
              {passwordErrors.upperCase && (
                <p className="generate_password_text">
                  Пароль повинен містити хоча б одну заголовну букву
                </p>
              )}
              {passwordErrors.lowerCase && (
                <p className="generate_password_text">
                  Пароль повинен містити хоча б одну строчну букву
                </p>
              )}
              {passwordErrors.number && (
                <p className="generate_password_text">
                  Пароль повинен містити хоча б одну цифру
                </p>
              )}
              {passwordErrors.latinLetters && (
                <p className="generate_password_text">
                  Пароль повинен містити тільки латинські букви
                </p>
              )}
            </div>
            <div className="admin_wrap_label admin_wrap_label_repeat">
              <label htmlFor="password_repeat">Повторіть новий пароль</label>
              <input
                type="password"
                name="password_repeat"
                value={passwordRepeat}
                onChange={(e) => setPasswordRepeat(e.target.value)}
              />
            </div>
            <div className="admin_wrap_buttons">
              <input type="submit" value="Змінити пароль" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
