import React, { useContext } from "react";
import axios from "axios";
import { useModal } from "../../Modal/ModalContext";
import { AddUserForm } from "./AddUserForm";
import { EditUserForm } from "./EditUserForm";
import { EditPassUserForm } from "./EditPassUserForm";
import { SearchContext } from "../../Search/SearchContext";
import ClipLoader from "react-spinners/ClipLoader";
import { Sort } from "../../Sort/Sort";
import { useNotification } from "../../NotificationModal/NotificationModal";

export const UserSettings = () => {
  const tableName = "users";
  const [data, setData] = React.useState([]);
  const { openModal } = useModal();
  const { searchQuery } = useContext(SearchContext);
  const [loading, setLoading] = React.useState(true);
  const { showNotification } = useNotification();

  React.useEffect(() => {
    axios
      .get(`/api/table/${tableName}`)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => console.error("Помилка:", error));
  }, []);

  // Функция для получения данных с учётом сортировки
  const fetchSortedData = (sort, order) => {
    axios
      .get(`/api/sort_user?sort=${sort}&order=${order}`)
      .then((response) => {
        setData(response.data); // Обновляем данные таблицы
      })
      .catch((error) => {
        console.error("Помилка отримання даних:", error);
      });
  };

  const handleAddUser = () => {
    openModal(<AddUserForm refreshData={() => fetchUsers()} />);
  };

  const handleEditUser = (user) => {
    openModal(<EditUserForm user={user} refreshData={fetchUsers} />);
  };

  const handleEditPassUser = (user) => {
    openModal(<EditPassUserForm user={user} refreshData={fetchUsers} />);
  };

  const handleDeleteUser = (userId) => {
    // Показываем подтверждение через alert
    const isConfirmed = window.confirm(
      "Ви впевнені, що хочете видалити цього користувача?"
    );
    if (!isConfirmed) return;

    axios
      .delete(`/api/delete_user/${userId}`)
      .then(() => {
        fetchUsers();
        showNotification("Користувача успішно видалено!", "success");
      })
      .catch((error) => {
        console.error("Помилка видалення:", error);
        showNotification("Не вдалося видалити користувача!", "failed");
      });
  };

  const fetchUsers = () => {
    axios
      .get(`/api/table/${tableName}`)
      .then((response) => setData(response.data))
      .catch((error) => console.error("Помилка:", error));
  };

  const filteredUsers = data.filter((user) =>
    user.username.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (loading) {
    return (
      <>
        <div className="container">
          <div className="loader">
            <ClipLoader size={100} color="red" radius={100} />
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="admin_main">
      <div className="container--big--admin">
        <div className="admin_main_title">
          <h1>Користувачі</h1>
          <button className="admin_main_title_button" onClick={handleAddUser}>
            Додати користувача
          </button>
        </div>
        <Sort fetchSortedData={fetchSortedData} />
        <div className="admin_main_content">
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Ім'я</th>
                <th>Коментар</th>
                <th>CRM</th>
                <th>Дата створення</th>
                <th>Дата оновлення</th>
                <th className="th_edit_pass"></th>
                <th className="th_edit"></th>
                <th className="th_delete"></th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.username}</td>
                  <td>{item.comment}</td>
                  <td>{item.crm || "Нет связанных CRM"}</td>
                  <td>{item.created_at}</td>
                  <td>{item.updated_at}</td>
                  <td className="actions th_edit_pass">
                    <button
                      className="edit_pass"
                      onClick={() => handleEditPassUser(item)}
                    >
                      Редагувати пароль
                    </button>
                  </td>
                  <td className="actions th_edit">
                    <button
                      className="edit"
                      onClick={() => handleEditUser(item)}
                    >
                      Редагувати
                    </button>
                  </td>
                  <td className="actions th_delete">
                    <button
                      className="delete"
                      onClick={() => handleDeleteUser(item.id)}
                    >
                      Видалити
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
