import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactQuill from "react-quill";
import ClipLoader from "react-spinners/ClipLoader";
import { useNotification } from "../../NotificationModal/NotificationModal";

export const EditBookManager = () => {
  const [bookManager, setBookManager] = useState([]);
  const [loading, setLoading] = useState(true);
  const { showNotification } = useNotification();

  useEffect(() => {
    fetchBookManager();
  }, []);

  const fetchBookManager = async () => {
    try {
      const response = await axios.get("/api/book_manager");
      if (Array.isArray(response.data)) {
        setBookManager(response.data);
      } else {
        console.error("API повернув неочікуваний формат даних:", response.data);
      }
    } catch (error) {
      console.error("Помилка загрузки даних:", error);
    } finally {
      setLoading(false); // Завершение загрузки
    }
  };

  const handleAddInfo = async () => {
    try {
      const response = await axios.post("/api/add_book_manager", {
        label: "Новий запис",
        content: "Новий контент",
      });
      if (response.data && response.data.id) {
        // Добавляем новую запись в локальное состояние
        setBookManager((prev) => [
          ...prev,
          {
            id: response.data.id,
            label: "Новий запис",
            content: "Новий контент",
          },
        ]);
        showNotification("Новий запис додано", "success");
        document
          .querySelector(".edit_admin_block")
          .lastElementChild.scrollIntoView({ behavior: "smooth" });
      } else {
        showNotification("Помилка при добавленні записи", "error");
      }
    } catch (error) {
      console.error("Помилка при добавленні записи:", error);
      showNotification("Помилка при добавленні записи", "error");
    }
  };

  const handleInputChange = (id, field, value) => {
    setBookManager((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, [field]: value } : item
      )
    );
  };

  const handleSubmit = (e, id) => {
    e.preventDefault();
    const itemToUpdate = bookManager.find((item) => item.id === id);
    if (!itemToUpdate) {
      showNotification("Елемент з указаним ID не знайдено", "error");
      return;
    }
    axios
      .put(`/api/edit_book_manager/${id}`, {
        label: itemToUpdate.label,
        content: itemToUpdate.content,
      })
      .then((res) => {
        showNotification("Дані успішно оновлено", "success");
      })
      .catch((err) => {
        showNotification("Помилка оновлення даних", "error");
      });
  };

  const handleDelete = (id) => {
    if (window.confirm("Ви впевнені, що хочете видалити цей елемент?")) {
      axios.delete(`/api/delete_book_manager/${id}`).then(() => {
        fetchBookManager();
        showNotification("Дані успішно видалено", "success");
      });
    }
  };

  if (loading) {
    return (
      <div className="container">
        <div className="loader-2">
          <ClipLoader size={100} color="red" radius={100} />
        </div>
      </div>
    );
  }

  if (!Array.isArray(bookManager)) {
    return <div>Помилка: дані не є масивом</div>; // Обработка ошибки
  }

  return (
    <div className="edit_admin_block">
      <div className="admin_main_title" style={{ width: "100%" }}>
        <h1>Книга менеджера</h1>
        <button
          className="admin_main_title_button"
          onClick={handleAddInfo} // Добавлен обработчик клика
        >
          Додати інформацію
        </button>
      </div>
      {bookManager.map((item) => (
        <div key={item.id} style={{ width: "100%" }}>
          <form
            className="admin_wrap"
            onSubmit={(e) => handleSubmit(e, item.id)}
          >
            <div className="admin_wrap_label">
              <input
                type="text"
                name="label"
                value={item.label || ""} // Добавлена защита от `undefined`
                onChange={(e) =>
                  handleInputChange(item.id, "label", e.target.value)
                }
              />
            </div>
            <div className="admin_wrap_content">
              <ReactQuill
                className="ql-editor-fixed"
                value={item.content || ""}
                onChange={(content) =>
                  handleInputChange(item.id, "content", content)
                }
                modules={{
                  toolbar: [
                    [{ header: [1, 2, 3, false] }],
                    ["bold", "italic", "underline", "strike"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["link"],
                    [{ color: [] }, { background: [] }],
                    [{ align: [] }],
                    ["clean"],
                  ],
                }}
                formats={[
                  "header",
                  "bold",
                  "italic",
                  "underline",
                  "strike",
                  "list",
                  "bullet",
                  "link",
                  "align",
                  "color",
                  "background",
                ]}
              />
            </div>
            <div className="admin_wrap_buttons">
              <input type="submit" value="Зберегти " />
              <button className="delete" onClick={() => handleDelete(item.id)}>
                Видалити
              </button>
            </div>
          </form>
        </div>
      ))}
    </div>
  );
};
