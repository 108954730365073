import React from "react";
import styles from "./Modal.module.scss";
import { useModal } from "./ModalContext";

export const Modal = () => {
  const { modalContent, isOpen, closeModal } = useModal();

  return (
    <div
      id="modal"
      className={isOpen ? styles.modal__main : styles.modal__hidden}
    >
      <div className={styles.modal__inner}>
        <div className={styles.modal}>
          <div className={styles.modal__content}>
            <button className={styles.modal__close} onClick={closeModal}>
              <svg
                className={styles.modal__close__icon}
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0.0703127 11.9043L5.1416 6.83301L0.0703127 1.76172L1.76074 0.0712888L6.83203 5.14258L11.9033 0.0712888L13.5937 1.76172L8.52246 6.83301L13.5937 11.9043L11.9033 13.5947L6.83203 8.52344L1.76074 13.5947L0.0703127 11.9043Z"></path>
              </svg>
            </button>
            {modalContent}
          </div>
        </div>
      </div>
    </div>
  );
};
