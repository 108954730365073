import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { useNotification } from "../../NotificationModal/NotificationModal";

export const AddProductForm = () => {
  const navigate = useNavigate();

  const [images, setImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [crmList, setCrmList] = useState([]);
  const [selectedCrm, setSelectedCrm] = useState([]);
  const [important_information, setImportantInformation] = useState("");
  const [reglament, setReglament] = useState("");

  const { showNotification } = useNotification();

  useEffect(() => {
    fetch("/api/table/crm")
      .then((response) => response.json())
      .then((data) => setCrmList(data))
      .catch((error) => console.error("Помилка загрузки CRM:", error));
  }, []);

  // Функция, которая добавляет файлы в стейт и формирует превью
  const addFiles = (files) => {
    // Проверка, чтобы не превысить лимит в 12 изображений
    if (images.length + files.length > 12) {
      alert("Максимум 12 зображень!");
      return;
    }

    // Создаем превью
    const newPreviews = files.map((file) => URL.createObjectURL(file));

    // Обновляем стейты
    setImagePreviews((prev) => [...prev, ...newPreviews]);
    setImages((prev) => [...prev, ...files]);
  };

  // Обработчик события paste
  useEffect(() => {
    const handlePaste = (event) => {
      const clipboardItems = event.clipboardData?.items || [];
      const newFiles = [];

      for (let i = 0; i < clipboardItems.length; i++) {
        const item = clipboardItems[i];
        // Проверяем, является ли скопированный элемент изображением
        if (item.type.indexOf("image") === 0) {
          const file = item.getAsFile();
          if (file) {
            newFiles.push(file);
          }
        }
      }

      // Если в буфере были изображения, добавляем их в стейт
      if (newFiles.length > 0) {
        event.preventDefault(); // чтобы не вставлялся «лишний» контент в поля ввода
        addFiles(newFiles);
      }
    };

    document.addEventListener("paste", handlePaste);

    // Очистка при размонтировании
    return () => {
      document.removeEventListener("paste", handlePaste);
    };
  }, [images]);

  // Обработчик выбора файлов из input
  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    addFiles(files);
  };

  const handleImageRemove = (index) => {
    const newPreviews = [...imagePreviews];
    const newImages = [...images];

    newPreviews.splice(index, 1);
    newImages.splice(index, 1);

    setImagePreviews(newPreviews);
    setImages(newImages);
  };

  const handleCrmChange = (crmId, isChecked) => {
    setSelectedCrm((prev) =>
      isChecked ? [...prev, crmId] : prev.filter((id) => id !== crmId)
    );
  };

  const handleChangeImportantInformation = (value) => {
    setImportantInformation(value);
  };

  const handleChangeReglament = (value) => {
    setReglament(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (images.length === 0) {
      alert("Додайте хоча б одне зображення!");
      return;
    }

    if (selectedCrm.length === 0) {
      alert("Оберіть хоча б одну CRM!");
      return;
    }

    const formData = new FormData();
    formData.append("id_product_name", e.target.id_product_name.value);
    formData.append("name", e.target.name.value);
    formData.append("price", e.target.price.value);
    formData.append("important_information", important_information);
    formData.append("reglament", reglament);
    formData.append("url", e.target.url.value);
    formData.append("url_video", e.target.url_video.value);

    // Добавляем выбранные CRM
    selectedCrm.forEach((crmId) => {
      formData.append("crmIds[]", crmId);
    });

    // Добавляем изображения в FormData
    images.forEach((image) => {
      formData.append("images", image);
    });

    fetch("/api/add_product", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        setImages([]);
        setImagePreviews([]);
        setSelectedCrm([]);
        navigate("/dashboard/products");
        showNotification("Товар успішно додано!", "success");
      })
      .catch((error) => {
        console.error("Помилка:", error);
        showNotification("Не вдалося додати товар!", "failed");
      });
  };

  return (
    <div className="admin_main">
      <div className="container--big--admin">
        <div className="admin_main_title">
          <h1>Додати товар</h1>
        </div>
        <div className="admin_main_content">
          <div className="admin_main_content_form">
            <form onSubmit={handleSubmit} style={{ width: "100%" }}>
              <div className="admin_main_content_form_input">
                <label htmlFor="name">Назва товару</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Назва товару"
                  required
                />
              </div>
              <div className="admin_main_content_form_input">
                <label htmlFor="price">Ціна (грн)</label>
                <input
                  type="number"
                  id="price"
                  name="price"
                  placeholder="Ціна товару"
                  required
                />
              </div>
              <div className="admin_main_content_form_input">
                <label htmlFor="id_product_name">ID продукта</label>
                <input
                  type="text"
                  id="id_product_name"
                  name="id_product_name"
                  placeholder="ID продукта"
                  required
                />
              </div>

              <div className="admin_main_content_form_input">
                <label htmlFor="important_information">
                  ВАЖЛИВА ІНФОРМАЦІЯ
                </label>
                <ReactQuill
                  className="ql-editor-fixed"
                  value={important_information}
                  onChange={handleChangeImportantInformation}
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, 3, false] }],
                      ["bold", "italic", "underline", "strike"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["link"],
                      [{ color: [] }, { background: [] }],
                      [{ align: [] }],
                      ["clean"],
                    ],
                  }}
                  formats={[
                    "header",
                    "bold",
                    "italic",
                    "underline",
                    "strike",
                    "list",
                    "bullet",
                    "link",
                    "align",
                    "color",
                    "background",
                  ]}
                />
              </div>

              <div className="admin_main_content_form_input">
                <label htmlFor="reglament">РЕГЛАМЕНТ</label>
                <ReactQuill
                  className="ql-editor-fixed"
                  value={reglament}
                  onChange={handleChangeReglament}
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, 3, false] }],
                      ["bold", "italic", "underline", "strike"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["link"],
                      [{ color: [] }, { background: [] }],
                      [{ align: [] }],
                      ["clean"],
                    ],
                  }}
                  formats={[
                    "header",
                    "bold",
                    "italic",
                    "underline",
                    "strike",
                    "list",
                    "bullet",
                    "link",
                    "align",
                    "color",
                    "background",
                  ]}
                />
              </div>

              <div className="admin_main_content_form_input">
                <label htmlFor="url">URL</label>
                <input type="text" id="url" name="url" placeholder="https://" />
              </div>
              <div className="admin_main_content_form_input">
                <label htmlFor="url_video">URL відео</label>
                <input
                  type="text"
                  id="url_video"
                  name="url_video"
                  placeholder="https://"
                />
              </div>

              <div className="admin_main_content_form_input">
                <label>CRM:</label>
                <div className="admin_main_content_form_checkbox_container">
                  {crmList.map((crm) => (
                    <div
                      className="admin_main_content_form_checkbox"
                      key={crm.id}
                    >
                      <input
                        type="checkbox"
                        id={`crm-${crm.id}`}
                        value={crm.id}
                        onChange={(e) =>
                          handleCrmChange(crm.id, e.target.checked)
                        }
                      />
                      <label htmlFor={`crm-${crm.id}`}>{crm.name}</label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="admin_main_content_form_input">
                <label htmlFor="images">Додати фото (макс. 12)</label>
                <input
                  type="file"
                  id="images"
                  multiple
                  accept="image/*"
                  onChange={handleImageUpload}
                />
                <div className="image-previews">
                  {imagePreviews.map((preview, index) => (
                    <div key={index} className="image-preview">
                      <img src={preview} alt={`Preview ${index}`} />
                      <button
                        type="button"
                        onClick={() => handleImageRemove(index)}
                      >
                        Х
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              <button className="action_button" type="submit">
                Додати товар
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
