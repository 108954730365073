import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import styles from "./Projects.module.scss";
import ClipLoader from "react-spinners/ClipLoader";
import { SearchContext } from "../Search/SearchContext";

export const CrmList = () => {
  const [crmList, setCrmList] = useState([]); // Список CRM
  const [loading, setLoading] = useState(true);
  const { searchQuery } = useContext(SearchContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCrm = async () => {
      const token = localStorage.getItem("token_user");

      if (!token) {
        console.error("Токен відсутній. Перенаправлення на сторінку входу.");
        navigate("/login");
        return;
      }

      try {
        const response = await axios.get("/api/user_crm", {
          headers: { "Authorization-User": `Bearer ${token}` },
        });
        setLoading(false);
        setCrmList(response.data);
      } catch (error) {
        console.error("Помилка загрузки CRM:", error);
        setLoading(false);
      }
    };

    fetchCrm();
  }, []);

  if (loading) {
    return (
      <>
        <div className="container">
          <div className="loader">
            <ClipLoader size={100} color="red" radius={100} />
          </div>
        </div>
      </>
    );
  }

  const filteredCrmList = crmList.filter((crm) =>
    crm.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div id="projects" className={[styles.projects, "page-section"].join(" ")}>
      <div className="container">
        <div className="projects__inner">
          <h1 className={styles.projects__title}>Всі проекти</h1>
          <ul>
            {filteredCrmList.length > 0 ? (
              filteredCrmList.map((crm) => (
                <li key={crm.id}>
                  <Link to={`/projects/crm/${crm.id}`} className={styles.item}>
                    <div className={styles.main}>
                      <div className={styles.title}>{crm.name}</div>
                      <div className={styles.functions}>
                        <svg
                          className={styles.arrow}
                          width="24"
                          height="16"
                          viewBox="0 0 24 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M23 9C23.5523 9 24 8.55228 24 8C24 7.44772 23.5523 7 23 7V9ZM0.292892 7.29289C-0.0976315 7.68342 -0.0976315 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM23 7L1 7V9L23 9V7Z"></path>
                        </svg>
                      </div>
                    </div>
                  </Link>
                </li>
              ))
            ) : (
              <p>Немає зв'язаних CRM</p>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};
