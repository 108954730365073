import React, { createContext, useState, useContext } from "react";
import styles from "./NotificationModal.module.scss";

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState(null);

  const showNotification = (message, type = "success") => {
    setNotification({ message, type });
    setTimeout(() => {
      setNotification(null);
    }, 5000);
  };

  const closeNotification = () => {
    setNotification(null); // Закрыть уведомление вручную
  };

  return (
    <NotificationContext.Provider value={{ notification, showNotification }}>
      {children}
      {notification && (
        <div
          className={`${styles.notificationOverlay} ${
            notification.type === "success"
              ? styles.successOverlay
              : styles.failedOverlay
          }`}
        >
          <div
            className={`${styles.notificationModalSuccess} ${
              notification.type === "success" ? styles.success : styles.failed
            }`}
          >
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 15H11.008M11 7V12M21 11C21 5.477 16.523 1 11 1C5.477 1 1 5.477 1 11C1 16.523 5.477 21 11 21C16.523 21 21 16.523 21 11Z"
                stroke="white"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <p>{notification.message}</p>
            <button className={styles.closeButton} onClick={closeNotification}>
              &times;
            </button>
          </div>
        </div>
      )}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => useContext(NotificationContext);
