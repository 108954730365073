import React, { useContext } from "react";
import logo from "../../assets/img/logo_admin_panel.png";
import styles from "./HeaderAdmin.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { SearchContext } from "../Search/SearchContext";

import { ToggleTheme } from "../ToggleTheme/ToggleTheme";

import avatar from "../../assets/svg/avatar.svg";

export const HeaderAdmin = () => {
  const navigate = useNavigate();
  const { searchQuery, setSearchQuery } = useContext(SearchContext);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleLogout = () => {
    localStorage.removeItem("token_admin");
    navigate("/login-admin-88cjw2d0b1mx9iis2");
  };
  return (
    <>
      <div className={styles.header}>
        <div className={styles.container}>
          <div className={styles.inner}>
            <div className={styles.left}>
              <Link to="/dashboard">
                <img className={styles.logo} src={logo} alt="logo" />
              </Link>
            </div>
            <div className={styles.middle}>
              <div className={styles.search}>
                <input
                  className={styles.Input_input}
                  name="search"
                  type="text"
                  placeholder="Пошук"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>
              <div className={styles.navigation}>
                <Link to="/dashboard/crm" className={styles.button}>
                  CRM
                </Link>
                <Link to="/dashboard/users" className={styles.button}>
                  Користувачі
                </Link>
                <Link to="/dashboard/products" className={styles.button}>
                  Продукти
                </Link>
                <Link to="/dashboard/" className={styles.button}>
                  Налаштування
                </Link>
              </div>
            </div>
            <div className={styles.right}>
              <ToggleTheme />
              <div className={styles.user}>
                <div className={styles.user__img}>
                  <img src={avatar} alt="avatar" />
                </div>
                <div className={styles.user__info}>
                  <div className={styles.user__name}>Admin</div>
                  <div className={styles.user__logout} onClick={handleLogout}>
                    Вихід
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
