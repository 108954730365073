import React, { useEffect, useRef, useState } from "react";

const Panel = ({ label, content, isActive, onClick }) => {
  const [height, setHeight] = useState(0);
  const innerRef = useRef(null);

  useEffect(() => {
    if (innerRef.current) {
      setHeight(innerRef.current.scrollHeight);
    }
  }, [isActive]);

  const innerStyle = {
    height: isActive ? `${height}px` : "0px",
    overflow: "hidden",
    transition: "height 0.3s ease",
  };

  const innerIconStyle = {
    transform: isActive ? "rotate(180deg)" : "rotate(0deg)",
    transition: "transform 0.3s ease",
  };

  return (
    <div className="panel">
      <div className="panel__title" onClick={onClick}>
        <button
          className="panel__label"
          role="tab"
          aria-expanded={isActive} // Устанавливаем состояние
        >
          {label}
        </button>
        <svg
          className="panel__icon"
          style={innerIconStyle}
          width="13"
          height="7"
          viewBox="0 0 13 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1L5.52778 5.59317C6.0625 6.13561 6.9375 6.13561 7.47222 5.59317L12 1"
            strokeWidth="1.2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </svg>
      </div>
      <div
        className="panel__inner"
        style={innerStyle}
        aria-hidden={!isActive} // Оставляем здесь aria-hidden
        ref={innerRef}
      >
        <div
          className="panel__content"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      </div>
    </div>
  );
};

export default Panel;
