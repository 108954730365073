import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useModal } from "../../Modal/ModalContext";
import axios from "axios";
import { AddBlockLearning } from "./AddBlockLearning";
import { EditSectionLearning } from "./EditSectionLearning";

import { useNotification } from "../../NotificationModal/NotificationModal";

export const EditBlockLearning = () => {
  const { openModal } = useModal();
  const { showNotification } = useNotification();
  const [educations, setEducations] = useState([]);

  useEffect(() => {
    axios
      .get("/api/educations")
      .then((res) => {
        setEducations(res.data);
      })
      .catch((err) => console.error("Помилка загрузки даних:", err));
  }, []);

  const handleOpenAddModal = () => {
    openModal(<AddBlockLearning />);
  };

  const handleOpenEditModal = (id) => {
    openModal(<EditSectionLearning id={id} />);
  };

  const handleDelete = (id) => {
    if (window.confirm("Ви впевнені, що хочете видалити цей матеріал?")) {
      axios.delete(`/api/delete_educations/${id}`).then((res) => {
        setEducations(educations.filter((education) => education.id !== id));
        showNotification("Матеріал успішно видалено!", "success");
      });
    }
  };

  return (
    <>
      <div className="">
        <div className="admin_main_title">
          <h1>Навчальні матеріали</h1>
          <button
            onClick={handleOpenAddModal}
            className="admin_main_title_button"
          >
            Додати навчальний матеріал
          </button>
        </div>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Назва</th>
              <th className="th_edit"></th>
              <th className="th_delete"></th>
            </tr>
          </thead>
          <tbody>
            {educations.map((education) => (
              <tr key={education.id}>
                <td>{education.id}</td>
                <td>{education.title}</td>
                <td className="actions th_edit">
                  <Link
                    onClick={() => handleOpenEditModal(education.id)}
                    className="edit"
                  >
                    Редагувати
                  </Link>
                </td>
                <td className="actions th_delete">
                  <button
                    onClick={() => handleDelete(education.id)}
                    className="delete"
                  >
                    Видалити
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
