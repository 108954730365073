import React from "react";
import styles from "./ProductReglament.module.scss";
import modalStyles from "../../Modal/Modal.module.scss";

export const ProductReglament = ({ productReglament }) => {
  return (
    <>
      <div>
        <h1 className={styles.ImportantInformation_main_title}>Регламент</h1>

        <div
          className={[
            styles.ImportantInformation_main_content,
            modalStyles.modal__body,
          ].join(" ")}
        >
          <div>
            {productReglament !== "" && (
              <div className={styles.ImportantInformation_wrap}>
                <div
                  className={styles.ImportantInformation_wrap_content}
                  dangerouslySetInnerHTML={{ __html: productReglament }}
                />
              </div>
            )}
            {productReglament === "" && (
              <div className={styles.ImportantInformation_wrap}>
                Поки що немає жодного розділу
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
