import React from "react";
import axios from "axios";
import { useModal } from "../../Modal/ModalContext";

import { useNotification } from "../../NotificationModal/NotificationModal";

export const AddCrmForm = ({ refreshData }) => {
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [reglament, setReglament] = React.useState("");
  const { closeModal } = useModal();
  const { showNotification } = useNotification();

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("/api/add_crm", {
        name,
        description,
        reglament,
      })
      .then(() => {
        closeModal();
        refreshData();
        showNotification("CRM успішно додано!", "success");
      })
      .catch((error) => {
        console.error("Помилка добавлення:", error);
        showNotification("Не вдалося додати CRM!", "failed");
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Додати CRM</h2>
      <div className="admin_main_content_form_input">
        <label>
          Ім'я:<span className="star__red">*</span>
        </label>
        <input
          type="text"
          placeholder="Назва CRM"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>
      <div className="admin_main_content_form_input">
        <label>Опис:</label>
        <textarea
          placeholder="Опис CRM"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
      <div className="admin_main_content_form_input">
        <input type="hidden" value={reglament} />
      </div>
      <button className="action_button" type="submit">
        Додати
      </button>
    </form>
  );
};
