import React, { useContext, useEffect, useState } from "react";
import logo from "../../assets/img/logo_dashboard.png";
import styles from "./HeaderUser.module.scss";
import { useNavigate } from "react-router-dom";
import avatar from "../../assets/svg/avatar.svg";
import { SearchContext } from "../Search/SearchContext";
import { ToggleTheme } from "../ToggleTheme/ToggleTheme";
import { useModal } from "../Modal/ModalContext";
import { BookManager } from "../Projects/BookManager/BookManager";
import axios from "axios";

export const HeaderUser = () => {
  const token = localStorage.getItem("token_user");
  const navigate = useNavigate();
  const { searchQuery, setSearchQuery } = useContext(SearchContext);
  const { openModal } = useModal();
  const [userName, setUserName] = useState(null);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    if (!token) {
      console.error("Токен відсутній. Перенаправлення на сторінку входу.");
      navigate("/login");
      return;
    }

    axios
      .get("/api/user/profile", {
        headers: { "Authorization-User": `Bearer ${token}` },
      })
      .then((res) => {
        setUserName(res.data[0].username);
      })
      .catch((err) => console.error("Помилка загрузки даних:", err));
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token_user");
    navigate("/login");
  };

  const handleOpenBookManager = () => {
    openModal(<BookManager />);
  };

  return (
    <div className={styles.header}>
      <div className={styles.container}>
        <div className={styles.inner}>
          <div className={styles.left}>
            <a href="/projects">
              <img className={styles.logo} src={logo} alt="logo" />
            </a>
          </div>
          <div className={styles.middle}>
            <div className={styles.search}>
              <input
                className={styles.Input_input}
                name="search"
                type="text"
                placeholder="Пошук"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
            <div className={styles.navigation}>
              <button
                className={styles.button}
                type="button"
                onClick={handleOpenBookManager} // Обработчик нажатия
              >
                Книга менеджера
              </button>
              <button
                className={styles.button}
                type="button"
                onClick={() => navigate("/projects/education")}
              >
                Навчання
              </button>
            </div>
          </div>
          <div className={styles.right}>
            <ToggleTheme />
            <div className={styles.user}>
              <div className={styles.user__img}>
                <img src={avatar} alt="avatar" />
              </div>
              <div className={styles.user__info}>
                <div className={styles.user__name}>{userName}</div>
                <div className={styles.user__logout} onClick={handleLogout}>
                  Вихід
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
