import React from "react";
import axios from "axios";
import { useModal } from "../../Modal/ModalContext";
import { useNotification } from "../../NotificationModal/NotificationModal";

export const AddUserForm = ({ refreshData }) => {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [comment, setComment] = React.useState("");
  const [crmList, setCrmList] = React.useState([]);
  const [selectedCrm, setSelectedCrm] = React.useState([]);
  const [usernameError, setUsernameError] = React.useState("");
  const { closeModal } = useModal();
  const { showNotification } = useNotification();

  React.useEffect(() => {
    axios
      .get("/api/table/crm")
      .then((response) => setCrmList(response.data))
      .catch((error) => console.error("Помилка загрузки CRM:", error));
  }, []);

  const handleUsernameChange = (e) => {
    const value = e.target.value;
    const isValid = /^[a-zA-Z0-9]*$/.test(value);
    setUsername(value);

    if (!isValid) {
      setUsernameError(
        "Допустимі тільки латинські літери та цифри без пробілів"
      );
    } else {
      setUsernameError("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!selectedCrm.length) {
      alert("Виберіть хоча б одну CRM!");
      return;
    }

    if (usernameError) {
      return;
    }

    axios
      .post("/api/add_user", {
        username,
        password,
        comment,
      })
      .then((response) => {
        const userId = response.data.id;

        axios
          .post("/api/add_user_crm", {
            userId,
            crmIds: selectedCrm,
          })
          .then(() => {
            closeModal();
            refreshData();
            showNotification("Користувача успішно додано!", "success");
          })
          .catch((error) => {
            console.error("Помилка привязки CRM:", error);
            showNotification("Не вдалося додати користувача!", "failed");
          });
      })
      .catch((error) => {
        console.error("Помилка добавления користувача:", error);
        showNotification("Не вдалося додати користувача!", "failed");
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Додати користувача</h2>
      <div className="admin_main_content_form_input">
        <label>
          Логін:<span className="star__red">*</span>
        </label>
        <input
          type="text"
          value={username}
          onChange={handleUsernameChange}
          required
        />
        {usernameError && (
          <p className="generate_password_text">{usernameError}</p>
        )}
      </div>
      <div className="admin_main_content_form_input">
        <label>
          Пароль:<span className="star__red">*</span>
        </label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
      </div>
      <div className="admin_main_content_form_input">
        <label>Коментар:</label>
        <textarea
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </div>
      <div className="admin_main_content_form_input">
        <label>CRM:</label>
        <div className="admin_main_content_form_checkbox_container">
          {crmList.map((crm) => (
            <div className="admin_main_content_form_checkbox" key={crm.id}>
              <input
                type="checkbox"
                id={`crm-${crm.id}`}
                value={crm.id}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedCrm((prev) => [...prev, crm.id]);
                  } else {
                    setSelectedCrm((prev) =>
                      prev.filter((id) => id !== crm.id)
                    );
                  }
                }}
              />
              <label htmlFor={`crm-${crm.id}`}>{crm.name}</label>
            </div>
          ))}
        </div>
      </div>

      <button className="action_button" type="submit">
        Додати
      </button>
    </form>
  );
};
